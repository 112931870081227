import React, { useState, ChangeEvent, FormEvent } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import { useSnackbar } from "../../SnackbarContext";
import styles from "./UserStyles.module.scss";
import { JSEncrypt } from "jsencrypt";

// import dayjs from "dayjs";
import {
	TextField,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	FormHelperText,
	SelectChangeEvent,
	Snackbar,
	Alert,
	Box,
} from "@mui/material";
import { SubmitCreateUser } from "../../services/UserService";
import { AnyCnameRecord } from "dns";

type InputFields = {
	FirstName: string;
	LastName: string;
	EmailId: string;
	Password: string;
	ConfirmPassword: string;
	ContactNumber: string;
	Gender: string;
	DateofBirth: string;
	OrganizationId: string;
};

type ErrorFields = Partial<InputFields>;

type TouchedFields = Partial<Record<keyof InputFields, boolean>>;

export default function UserCreate(props: any) {
	const {
		setModels,
		setLoading,
		setShowPopup,
		setShowEditPopup,
		skip,
		top,
		OrganizationId,
		setTotalcount,
		orderByFields,
		filters,
	} = props;
	const { openSnackbar } = useSnackbar();
	const [inputs, setInputs] = useState<InputFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		Password: "",
		ConfirmPassword: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: OrganizationId,

		// OrganizationId: OrganizationId,
		// Status: "true",
		// //OrganizationId: "01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863",
	});

	const [errors, setErrors] = useState<ErrorFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		Password: "",
		ConfirmPassword: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: "",
	});

	const [isTouched, setIsTouched] = useState<TouchedFields>({
		FirstName: false,
		LastName: false,
		EmailId: false,
		Password: false,
		ConfirmPassword: false,
		ContactNumber: false,
		Gender: false,
		DateofBirth: false,
		OrganizationId: false,
	});
	const [showPassword, setShowPassword] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const encryptData = (data: any) => {
		const publicKey = `-----BEGIN PUBLIC KEY-----
		MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHPeXxbMNqVNKTZvcbgLqb1itpw7U2wvk9n+qJ4MulRuZXJyVa7BlOboOlE8JZbYi7+i00xLShd7BpHKuRPacUnF2XKVEVbGrFSDKD3oOy9ji36y3HzBfrfiZzK9Q3YtIlVdBBLILd9PXSKQoAlYQqU73N1cKEZf9NOSzyZsnUpJAgMBAAE=
		-----END PUBLIC KEY-----`;
		const encrypt = new JSEncrypt();
		encrypt.setPublicKey(publicKey);
		const encryptedData = encrypt.encrypt(data);
		return encryptedData;
	};

	const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setInputs((prev) => ({ ...prev, [name]: value }));
		let processedValue = value;
		let error = "";

		if (name === "FirstName" || name === "LastName") {
			// Remove special characters
			processedValue = value.replace(/[^a-zA-Z]/g, "");
		}

		setInputs((prev) => ({ ...prev, [name]: processedValue }));

		if (name === "FirstName" && processedValue.length < 5) {
			error = "First Name must be at least 5 characters.";
		}

		// Validation for LastName
		if (name === "LastName" && processedValue.length < 3) {
			error = "Last Name must be at least 3 characters.";
		}

		if (name === "Password") {
			const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/;
			if (!passwordRegex.test(value)) {
				error =
					"Password must contain 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be 8-20 characters long.";
			}
			if (value.length < 8 || value.length > 12) {
				error = "Password must be 8-12 characters long";
			} else if (!/[A-Z]/.test(value)) {
				error = "Password must contain one uppercase letter";
			} else if (!/[a-z]/.test(value)) {
				error = "Password must contain one lowercase letter";
			} else if (!/\d/.test(value)) {
				error = "Password must contain one number";
			} else if (!/[!@#$%^&*]/.test(value)) {
				error = "Password must contain one special character";
			} else {
				error = "";
			}
		}
		if (name === "ConfirmPassword") {
			if (value !== inputs.Password) {
				error = "Passwords do not match.";
			}
		}

		if (name === "ContactNumber") {
			// Format contact number as '+XX-XXXXXXXXXX'
			const numericValue = value.replace(/[^0-9+]/g, "");
			if (numericValue.length !== 13 || !numericValue.startsWith("+") || numericValue[3] !== "-") {
				error = "Contact Number must be in the format +XX-XXXXXXXXXX.";
			}
			processedValue = numericValue;
			setInputs((prev) => ({ ...prev, [name]: processedValue }));
		}

		// if (name !== "Gender" && name !== "DateofBirth" && name !== "ContactNumber" && value.length < 5) {
		// 	error = `${name} must be at least 5 characters.`;
		// }

		if (name === "EmailId") {
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (value && !emailRegex.test(value)) {
				error = "Invalid email format.";
			}
		}
		setIsValid(true);
		setErrors((prev) => ({ ...prev, [name]: error }));
	};

	const handleSelectChange = (event: SelectChangeEvent<string>) => {
		const { name, value } = event.target;
		setInputs((prev) => ({ ...prev, [name as string]: value }));

		let error = "";
		if (value === "") {
			error = `${name} is required.`;
		}
		setErrors((prev) => ({ ...prev, [name as string]: error }));
	};

	// const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
	//     const { name } = event.target;
	//     if (name) {
	//         setIsTouched((prev) => ({ ...prev, [name]: true }));
	//         if (!inputs[name]) {
	//             setErrors((prev) => ({ ...prev, [name]: `${name} is required.` }));
	//         }
	//     }
	// };
	const [selectedDate, setSelectedDate] = useState();

	const handleDateChange = (date: any) => {
		setSelectedDate(date);
		setInputs((prev) => ({ ...prev, DateofBirth: date ? date.format("MM/DD/YYYY") : "" }));
		if (!date) {
			setErrors((prev) => ({ ...prev, DateofBirth: "Date of Birth is required." }));
		} else {
			setErrors((prev) => ({ ...prev, DateofBirth: "" }));
		}
	};
	const [age, setAge] = React.useState("");
	const handleGenderChange = (event: SelectChangeEvent) => {
		setAge(event.target.value);
		const value = event.target.value;
		setInputs((prev: any) => ({
			...prev,
			Gender: value,
		}));
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const newErrors: ErrorFields = {};

		let errorMessages = "";

		(Object.keys(inputs) as (keyof InputFields)[]).forEach((key) => {
			if (!inputs[key]) {
				newErrors[key] = `${key} is required.`;
				setIsValid(false);
				//isValid = false;
			} else if (key === "FirstName") {
				if (inputs[key].length < 5) {
					newErrors[key] = "First Name must be at least 5 characters.";
					setIsValid(false);
					//isValid = false;
				}
				if (/[^a-zA-Z]/g.test(inputs[key])) {
					newErrors[key] = "First Name can only contain letters and numbers.";
					setIsValid(false);
					//isValid = false;
				}
			} else if (key === "LastName") {
				if (inputs[key].length < 3) {
					newErrors[key] = "Last Name must be at least 3 characters.";
					setIsValid(false);
					//isValid = false;
				}
				if (/[^a-zA-Z]/g.test(inputs[key])) {
					newErrors[key] = "Last Name can only contain letters and numbers.";
					setIsValid(false);
					//isValid = false;
				}
			} else if (key === "Password") {
				const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/;
				if (!passwordRegex.test(inputs[key])) {
					newErrors[key] = "Password does not meet the criteria.";
					setIsValid(false);
					//isValid = false;
				}
			} else if (key === "ConfirmPassword") {
				if (inputs[key] !== inputs.Password) {
					newErrors[key] = "Passwords do not match.";
					setIsValid(false);
					//isValid = false;
				}
			} else if (key === "ContactNumber") {
				const contactNumberRegex = /^\+\d{2}-\d{10}$/;
				if (!contactNumberRegex.test(inputs[key])) {
					newErrors[key] = "Contact Number must be in the format +XX-XXXXXXXXXX.";
					setIsValid(false);
				}
			} else if (key === "EmailId") {
				const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
				if (!emailRegex.test(inputs[key])) {
					newErrors[key] = "Invalid email format.";
					setIsValid(false);
					//isValid = false;
				}
			}
		});

		// Concatenate all error messages
		errorMessages = Object.values(newErrors).filter(Boolean).join(", ");
		const encryptedPassword = encryptData(inputs.Password);
		const userCreateCommand = {
			...inputs,
			Password: encryptedPassword,
		};

		setErrors(newErrors);
		console.log(newErrors + "" + isValid);
		if (isValid) {
			setLoading(true);
			try {
				SubmitCreateUser(
					setLoading,
					setShowPopup,
					setModels,
					setShowEditPopup,
					userCreateCommand,
					OrganizationId,
					skip,
					top,
					setTotalcount,
					openSnackbar,
					orderByFields,
					filters
				);
			} catch (error: any) {
				openSnackbar("Failed to create user.", "error");
			}
		} else {
			openSnackbar(errorMessages, "error");
		}
	};

	// const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
	// 	event.preventDefault();
	// 	const newErrors: ErrorFields = {};
	// 	let isValid = true;

	// 	(Object.keys(inputs) as (keyof InputFields)[]).forEach((key) => {
	// 		if (!inputs[key]) {
	// 			newErrors[key] = `${key} is required.`;
	// 			isValid = false;
	// 		} else if (key === "FirstName") {
	// 			if (inputs[key].length < 5) {
	// 				newErrors[key] = "First Name must be at least 5 characters.";
	// 				isValid = false;
	// 			}
	// 			if (/[^a-zA-Z0-9]/.test(inputs[key])) {
	// 				newErrors[key] = "First Name can only contain letters and numbers.";
	// 				isValid = false;
	// 			}
	// 		} else if (key === "LastName") {
	// 			if (inputs[key].length < 3) {
	// 				newErrors[key] = "Last Name must be at least 3 characters.";
	// 				isValid = false;
	// 			}
	// 			if (/[^a-zA-Z0-9]/.test(inputs[key])) {
	// 				newErrors[key] = "Last Name can only contain letters and numbers.";
	// 				isValid = false;
	// 			}
	// 		} else if (key === "Password") {
	// 			const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/;
	// 			if (!passwordRegex.test(inputs[key])) {
	// 				newErrors[key] = "Password was not as mentioned ";
	// 				isValid = false;
	// 			}
	// 		} else if (key === "ConfirmPassword") {
	// 			if (inputs[key] !== inputs.Password) {
	// 				newErrors[key] = "Passwords do not match.";
	// 				isValid = false;
	// 			}
	// 		} else if (key === "ContactNumber") {
	// 			if (!/^\d{10}$/.test(inputs[key])) {
	// 				newErrors[key] = "Contact Number must be numeric and have exactly 10 digits.";
	// 				isValid = false;
	// 			}
	// 		} else if (key === "EmailId") {
	// 			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	// 			if (!emailRegex.test(inputs[key])) {
	// 				newErrors[key] = "Invalid email format.";
	// 				isValid = false;
	// 			}
	// 		}
	// 	});

	// 	setErrors(newErrors);
	// 	if (isValid) {
	// 		setLoading(true);
	// 		try {
	// 			SubmitCreateUser(
	// 				setLoading,
	// 				setShowPopup,
	// 				setModels,
	// 				setShowEditPopup,
	// 				inputs,
	// 				OrganizationId,
	// 				skip,
	// 				top,
	// 				setTotalcount,
	// 				openSnackbar,
	// 				orderByFields,
	// 				filters
	// 			);
	// 			//openSnackbar("User  created successfully!", "success");
	// 		} catch (error: any) {
	// 			//openSnackbar("Failed to create user .", "error");
	// 			openSnackbar(error, "error");
	// 		}
	// 	} else {
	// 		openSnackbar(errors, "error");
	// 	}
	// };

	//const dateOfBirthDayjs = dayjs(inputs.dateOfBirth);
	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	return (
		<div
			className={styles.userEditpopup}
			//className="userEdit-popup"
		>
			<div>
				<h1>Add Member</h1>
				<svg
					onClick={() => setShowPopup(false)}
					className="closeusercreate-icon"
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					width="24"
					height="24"
					viewBox="0 0 50 50"
				>
					<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
				</svg>
			</div>
			<form
				onSubmit={handleSubmit}
				noValidate
			>
				<TextField
					name="FirstName"
					value={inputs.FirstName}
					onChange={handleTextFieldChange}
					// onBlur={handleBlur}
					label="First Name*"
					helperText={errors.FirstName}
					variant="standard"
					error={!!errors.FirstName}
					inputProps={{ minLength: 5, maxLength: 50 }}
					sx={{
						"& .MuiFormHelperText-root": {
							color: errors.FirstName ? "red" : "inherit",
						},
						width: "250px",
						marginBottom: "8px",
					}}
				/>
				<TextField
					name="LastName"
					value={inputs.LastName}
					onChange={handleTextFieldChange}
					// onBlur={handleBlur}
					label="Last Name*"
					helperText={errors.LastName}
					variant="standard"
					error={!!errors.LastName}
					inputProps={{ minLength: 3, maxLength: 50 }}
					sx={{
						"& .MuiFormHelperText-root": {
							color: errors.LastName ? "red" : "inherit",
						},
						width: "250px",
						marginBottom: "8px",
					}}
				/>

				<TextField
					name="EmailId"
					value={inputs.EmailId}
					onChange={handleTextFieldChange}
					label="Email"
					helperText={errors.EmailId}
					placeholder="example@gmail.com"
					variant="standard"
					error={!!errors.EmailId}
					required
					sx={{
						"& .MuiFormHelperText-root": {
							color: errors.EmailId ? "red" : "inherit",
						},
						width: "250px",
						marginBottom: "8px",
					}}
				/>

				<TextField
					name="Password"
					type={showPassword ? "text" : "password"}
					value={inputs.Password}
					onChange={handleTextFieldChange}
					label="Password"
					helperText={errors.Password}
					variant="standard"
					error={!!errors.Password}
					required
					inputProps={{ minLength: 8, maxLength: 20 }}
					sx={{
						"& .MuiFormHelperText-root": {
							color: errors.Password ? "red" : "inherit",
						},
						width: "250px",
						marginBottom: "8px",
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleTogglePasswordVisibility}
									onMouseDown={(event) => event.preventDefault()}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<TextField
					name="ConfirmPassword"
					type={showPassword ? "text" : "password"}
					value={inputs.ConfirmPassword}
					onChange={handleTextFieldChange}
					label="Confirm Password"
					helperText={errors.ConfirmPassword}
					variant="standard"
					error={!!errors.ConfirmPassword}
					required
					inputProps={{ minLength: 8, maxLength: 20 }}
					sx={{
						"& .MuiFormHelperText-root": {
							color: errors.Password ? "red" : "inherit",
						},
						width: "250px",
						marginBottom: "8px",
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleTogglePasswordVisibility}
									onMouseDown={(event) => event.preventDefault()}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<TextField
					name="ContactNumber"
					value={inputs.ContactNumber}
					onChange={(event) => {
						// Allow only digits after the '+' and '-' symbols
						let value = event.target.value;
						// Remove everything except digits and '+' symbol
						let cleanedValue = value.replace(/[^0-9+]/g, "");
						// Ensure it starts with '+' and is followed by the country code (two digits)
						if (cleanedValue.startsWith("+")) {
							cleanedValue = "+" + cleanedValue.slice(1, 3) + "-" + cleanedValue.slice(3);
						}
						setInputs((prev) => ({ ...prev, ContactNumber: cleanedValue }));
					}}
					label="Contact Number"
					helperText={errors.ContactNumber}
					placeholder="+XX-XXXXXXXXXX"
					variant="standard"
					error={!!errors.ContactNumber}
					required
					inputProps={{
						inputMode: "numeric",
						pattern: "\\+\\d{2}-\\d{10}",
						maxLength: 14, // '+XX-XXXXXXXXXX' has 14 characters
					}}
					sx={{
						"& .MuiFormHelperText-root": {
							color: errors.ContactNumber ? "red" : "inherit",
						},
						width: "250px",
						marginBottom: "8px",
					}}
				/>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						label="Date of Birth"
						value={selectedDate}
						onChange={handleDateChange}
						renderInput={(params) => (
							<TextField
								{...params}
								error={Boolean(errors.DateofBirth)}
								helperText={errors.DateofBirth}
								variant="standard"
								required
							/>
						)}
					/>
				</LocalizationProvider>
				<FormControl
					variant="standard"
					sx={{ m: 1, minWidth: 120 }}
				>
					<InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
					<Select
						name="Gender"
						labelId="demo-simple-select-standard-label"
						id="demo-simple-select-standard"
						value={age}
						onChange={handleGenderChange}
						label="Gender"
					>
						<MenuItem value={"Male"}>Male</MenuItem>
						<MenuItem value={"Female"}>Female</MenuItem>
						<MenuItem value={"Other"}>Other</MenuItem>
					</Select>
				</FormControl>

				{/* <br />
				 <Button
					type="cancel"
					variant="contained"
					color="primary"
					// onClick={setShowPopup(false)}
					onClick={() => setShowPopup(false)}
					className="userButton"
				>
					Cancel
				</Button> */}
				<br />

				<div
					className={styles.userCreateButtonsdiv}
					//style={{ display: "flex", gap: "80px", position: "relative", top: "80px" }}
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => setShowPopup(false)}
					>
						Cancel
					</Button>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={isValid === false}
					>
						Submit
					</Button>
				</div>
				<br />
				<br />
			</form>
		</div>
	);
}
