import React, { useState, useEffect } from "react";
import CreateAccount from "./AccountCreate";
import {
	GridColDef,
	GridRenderCellParams,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	DataGrid,
	GridPaginationModel,
	GridColumnMenuProps,
} from "@mui/x-data-grid";
import { Button, Menu, MenuItem, FormControlLabel, IconButton, Snackbar, Alert, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import loader from "../../assets/loader.gif";
import CustomGrid from "../common/Grid";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
//import { AccountData } from "./AccountData";
import EditAccount from "./AccountEdit";
import { GetAllAccounts, fetchDeleteAccountDetails, GetAccountsList } from "../../services/AccountService";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { organizationsList } from "../organization/orgData";
import CustomColumnMenu from "../CustomColumnMenu";
import AccountCustomColumnMenu from "./AccountsColumnMenu";
import { useSnackbar } from "../../SnackbarContext";
import styles from "./AccountStle.module.scss";

type InputFields = {
	AccountName: string;
};
type ErrorFields = Partial<InputFields>;
interface CustomDataGridProps extends React.ComponentProps<typeof DataGrid> {
	components?: {
		columnMenu?: React.ComponentType<GridColumnMenuProps>;
	};
}

interface Model {
	AccountName: string | null;
	AdminType: string;
	AccountId: string;
}

const AccountList = () => {
	const [showPopup, setShowPopup] = useState(false);
	const [models, setModels] = useState<Model[]>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [loading, setLoading] = useState(false);
	const [accountidedit, setAccountIdEdit] = useState("");
	const [showeditPopup, setShowEditPopup] = useState(false);
	const [emailiddelete, setemailiddelete] = useState("");
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const storedOrganizationString = localStorage.getItem("organization");
	const storedOrganization = storedOrganizationString ? JSON.parse(storedOrganizationString) : null;
	const OrganizationId = storedOrganization.OrganizationId;
	const [skip, setskip] = useState(0);
	const [top, settop] = useState(0);
	const [totalcount, setTotalcount] = useState(0);
	const [searchText, setSearchText] = useState("");
	const { openSnackbar } = useSnackbar();
	const [orderByFields, setOrderByFields] = useState("");
	const [sortModel, setSortModel] = useState([]);
	const [filters, setFilters] = useState([]);
	const handleSortModelChange = (model: any) => {
		setSortModel(model);
		const orderByField = model
			.map((item: any) => (item.sort === "desc" ? `${item.field} desc` : item.field))
			.join(", ");
		//console.log(orderByField);
		setOrderByFields(orderByField);
		// Fetch data with new sort order
		//GetAccountsList(setFilteredRows, setLoading, OrganizationId, paginationModel.page * paginationModel.pageSize, paginationModel.pageSize, setTotalcount, orderByFields);
		GetAccountsList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByField, filters);
	};
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		page: 0,
		pageSize: 10,
	});
	const [errors, setErrors] = useState<ErrorFields>({
		AccountName: "",
	});
	const [AccountDeleteDetails, setAccountDeleteDetails] = useState({
		AccountId: "",
		AccountName: "",
		AccountType: "",
		CreatedBy: "",
		CreatedDate: "",
		OrganizationId: "",
		UpdatedBy: "",
		UpdatedDate: "",
		Active: Boolean(true),
	});

	// useEffect(() => {
	// 	setModels(AccountData.data);
	// }, []);

	const FetchAccounts = () => {
		setLoading(true);
		const skipcount = paginationModel.pageSize || 10;
		//console.log(paginationModel.pageSize, "pageSize");
		const limitcount = paginationModel.page * skipcount;
		const skips = limitcount;
		const top = paginationModel.pageSize;
		setskip(skips);
		settop(top);
		GetAccountsList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, filters);
	};

	useEffect(() => {
		setLoading(true);
		//GetAllAccounts(setModels, setLoading);
		FetchAccounts();
	}, [paginationModel]);

	useEffect(() => {
		if (models.length !== 0) {
			setLoading(false);
		}
	}, [models]);

	useEffect(() => {
		const unsubscribe = subscribe(setSidebarOpen);
		return () => unsubscribe();
	}, []);

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	const MatEdit = (number: any) => {
		const handleMailClick = (type: any) => {
			// some action
			//console.log(type);
		};

		const handleDeleteClick = (emailId: string, accountid: string) => {
			//.log("delete");
			setShowDeletePopup(true);
			//console.log(emailId);
			setemailiddelete(emailId);
			setAccountIdEdit(accountid);
		};
		const handleeditclick = (accountid: string) => {
			//console.log("edit");
			setShowEditPopup(true);
			//console.log(accountid);
			setAccountIdEdit(accountid);
		};
		const handleClose = () => {};

		return (
			<div>
				{/* <FormControlLabel
					control={
						<IconButton
							color="secondary"
							aria-label="add an alarm"
							onClick={() => handleMailClick("mail")}
						>
							{number.emailConfirmed === true ? (
								<MarkEmailReadIcon style={{ color: "blue" }} />
							) : (
								<MailIcon style={{ color: "blue" }} />
							)}
						</IconButton>
					}
					label={""}
				/> */}
				<FormControlLabel
					control={
						<IconButton
							color="secondary"
							aria-label="add an alarm"
							onClick={() => {
								//openEditPopup();
								handleeditclick(number.accountid);
							}}
						>
							<EditIcon style={{ color: "blue" }} />
						</IconButton>
					}
					label={""}
				/>
				<FormControlLabel
					control={
						<IconButton
							color="secondary"
							aria-label="add an alarm"
							onClick={() => handleDeleteClick(number.emailId, number.accountid)}
						>
							<DeleteIcon style={{ color: "blue" }} />
						</IconButton>
					}
					label={""}
				/>
			</div>
		);
	};
	const columns: GridColDef[] = [
		// {
		// 	field: "AccountId",
		// 	headerName: "Account Id",
		// 	width: 150,
		// },
		{
			field: "AccountName",
			headerName: "Account Name",
			width: 350,
			flex: 1,
		},
		{
			field: "CreatedDate",
			headerName: "Created On",
			width: 350,
			flex: 1,
			disableColumnMenu: true,
		},
		// {
		// 	field: "AccountType",
		// 	headerName: "Account Type",
		// 	width: 150,
		// },
		// {
		// 	field: "CreatedBy",
		// 	headerName: "Created By",
		// 	width: 300,
		// },
		// {
		// 	field: "UpdatedBy",
		// 	headerName: "Updated By",
		// 	width: 300,
		// },
		// {
		// 	field: "CreatedDate",
		// 	headerName: "Created Date",
		// 	width: 300,
		// },
		// {
		// 	field: "UpdatedDate",
		// 	headerName: "Updated Date",
		// 	width: 300,
		// },

		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 200,
			flex: 1,
			renderCell: (params) => {
				//const emailConfirmed = params.row.CreatedBy || false;
				const emailId = params.row.CreatedBy || false;
				const accountid = params.row.AccountId || false;
				return (
					<div
						className="d-flex justify-content-between align-items-center"
						style={{ cursor: "pointer" }}
					>
						<MatEdit
							//emailConfirmed={emailConfirmed}
							emailId={emailId}
							index={params.row.id}
							accountid={accountid}
						/>
					</div>
				);
			},
		},
	];

	const onPageChange = (newPage: number) => {
		//console.log("Page changed to:", newPage);
	};
	const onPageSizeChange = (newPageSize: number) => {
		//console.log("Page size changed to:", newPageSize);
	};
	const handleDownloadExcel = async () => {
		try {
			// const response = await fetchorganizationlistData()
			// const url = process.env.REACT_APP_BASE_URL
			// if (response.Msg_Code === "802.171.251.01" && response.File_Path) {
			// 	const downloadLink = document.createElement("a")
			// 	downloadLink.href = `${url}${response.File_Path}`
			// 	downloadLink.download = "excel_data.xlsx"
			// 	document.body.appendChild(downloadLink)
			// 	downloadLink.click()
			// 	document.body.removeChild(downloadLink)
			// } else {
			// 	console.error("Invalid response from the server:", response)
			// 	throw new Error("Invalid response from the server")
			// }
		} catch (error) {
			console.error("Error downloading Excel:", error);
		}
	};
	// useEffect(() => {
	// 	if (showDeletePopup) {
	// 		fetchAccountDetails(accountidedit);
	// 	}
	// }, [showDeletePopup]);
	// const fetchAccountDetails = async (id: any) => {
	// 	try {
	// 		const response = await fetch(`${adminUrl}/Account/GetAccountById?accountId=${id}`);
	// 		if (!response.ok) {
	// 			throw new Error("Network response was not ok");
	// 		}
	// 		const data = await response.json();
	// 		setAccountDeleteDetails({
	// 			AccountId: data.AccountId,
	// 			AccountName: data.AccountName,
	// 			AccountType: data.AccountType,
	// 			CreatedBy: data.CreatedBy,
	// 			CreatedDate: data.CreatedDate,
	// 			OrganizationId: data.OrganizationId,
	// 			UpdatedBy: data.UpdatedBy,
	// 			UpdatedDate: data.UpdatedDate,
	// 			Active: false,
	// 		});
	// 	} catch (error) {
	// 		console.error("Failed to fetch user details:", error);
	// 	}
	// };

	const handleDeleteAccount = () => {
		fetchDeleteAccountDetails(
			accountidedit,
			setLoading,
			setModels,
			setShowDeletePopup,
			OrganizationId,
			skip,
			top,
			setTotalcount,
			setSnackbarMessage,
			setSnackbarSeverity,
			setSnackbarOpen,
			openSnackbar,
			orderByFields,
			filters
		);
		//DeleteAccountDetails(setLoading, setModels, setShowDeletePopup, AccountDeleteDetails);
	};

	const CustomToolbar: React.FC<any> = () => {
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

		const handleExportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleExportMenuClose = () => {
			setAnchorEl(null);
		};

		const handleDownloadExcelClick = () => {
			handleExportMenuClose();
		};

		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</GridToolbarContainer>
				<Button
					aria-controls="export-menu"
					aria-haspopup="true"
					onClick={handleExportMenuClick}
					style={{ marginLeft: "10px" }}
					startIcon={<SaveAltIcon />}
				>
					Export
				</Button>
				<Menu
					id="export-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleExportMenuClose}
				>
					<MenuItem
						onClick={() => {
							handleDownloadExcel();
							handleExportMenuClose();
						}}
					>
						Download Excel
					</MenuItem>
				</Menu>
			</div>
		);
	};

	console.log();
	const openPopup = () => {
		setShowPopup(true);
	};
	const handleSearch = (value: string[]) => {
		setSearchText(value.join(" "));
	};

	return (
		<div
			className={`smooth-transition`}
			style={{
				marginLeft: sidebarOpen ? "220px" : "",
				marginRight: "6px",
				backgroundColor: "white",
				borderRadius: "6px",
			}}
		>
			<div
				className={`smooth-transition`}
				style={{ marginLeft: sidebarOpen ? "0px" : "-200px" }}
			>
				<div>
					<div className={sidebarOpen ? styles.AccountHeaderPositionsideon : styles.AccountHeaderPositionsideoff}>
						<h1>Account List </h1>
					</div>
					<div className={styles.AccountCreateButtonPosition}>
						<button
							onClick={openPopup}
							className="userButton"
						>
							Create Account
						</button>
					</div>
					<div>
						{showPopup ? (
							<CreateAccount
								setModels={setModels}
								setLoading={setLoading}
								showPopup={showPopup}
								setShowPopup={setShowPopup}
								orderByField={orderByFields}
							/>
						) : (
							""
						)}
					</div>

					{showeditPopup ? (
						<EditAccount
							showEditPopup={showeditPopup}
							setShowEditPopup={setShowEditPopup}
							accountidedit={accountidedit}
							GetAllAccounts={GetAllAccounts}
							setModels={setModels}
							setLoading={setLoading}
							organizationId={OrganizationId}
							skip={skip}
							top={top}
							setTotalcount={setTotalcount}
							orderByField={orderByFields}
							filters={filters}
							setFilters={setFilters}
						/>
					) : (
						""
					)}

					{loading ? (
						<div
							style={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								zIndex: 1,
							}}
						>
							<img
								src={loader}
								alt="Spinner"
								style={{
									width: "45px",
									height: "45px",
								}}
							/>
							<br />
						</div>
					) : (
						<div
							style={{
								// width: "500px",
								position: "relative",
								marginLeft: "200px",
							}}
						>
							{/* <CustomGrid
								rows={models}
								columns={columns}
								pageSize={100}
								totalRows={models.length}
								onPageChange={onPageChange}
								onPageSizeChange={onPageSizeChange}
								rowsPerPageOptions={[15, 25, 50, 100]}
								Toolbar={CustomToolbar}
							/> */}
							<Box
								className="userlistGrid"
								sx={{
									height: 467,
									width: sidebarOpen ? "100%" : "100%",
									marginTop: 2,
									marginLeft: sidebarOpen ? "-151px" : "1px",
									top: "-20px",
									position: "relative",
								}}
							>
								<DataGrid
									sx={{
										borderColor: "black",
										"& .MuiDataGrid-columnHeaders": {
											backgroundImage: "linear-gradient(to right, rgb(30, 138, 201), rgba(62, 200, 241, 0.7))",
										},
										"& .MuiDataGrid-columnHeaderTitle": {
											fontWeight: "bold",
											color: "white",
										},
										"& .MuiDataGrid-columnHeader": {
											backgroundImage: "linear-gradient(to right, rgb(30, 138, 201), rgba(62, 200, 241, 0.7))",
											borderBottom: "1px solid #ddd",
											color: "white",
										},
										"& .MuiDataGrid-columnHeader--alignLeft": {
											backgroundImage: "linear-gradient(to right, rgb(30, 138, 201), rgba(62, 200, 241, 0.7))",
											color: "white",
										},
										"& .MuiDataGrid-cell": {
											borderBottom: "1px solid #ddd", // Horizontal border for cells
											borderRight: "1px solid #ddd", // Vertical border for cells
										},
										"& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
											borderRight: "1px solid #ddd", // Border for both headers and cells
										},
										"& .MuiDataGrid-row": {
											"&:last-child .MuiDataGrid-cell": {
												borderBottom: "none", // Remove border from the last row
											},
										},
									}}
									rows={models}
									columns={columns}
									pagination
									paginationMode="server"
									slots={{
										columnMenu: (menuProps) =>
											menuProps.colDef.field === "AccountName" ? (
												<AccountCustomColumnMenu
													column={menuProps.colDef.field}
													setModels={setModels}
													setLoading={setLoading}
													skip={skip}
													top={top}
													OrganizationId={OrganizationId}
													setTotalcount={setTotalcount}
													orderByFields={orderByFields}
													filters={filters}
													setFilters={setFilters}
													{...menuProps}
													options={models.map((model: any) => model.AccountName || "")}
													onSearch={handleSearch}
												/>
											) : null,
									}}
									getRowId={(row) => row.AccountId}
									paginationModel={paginationModel}
									onPaginationModelChange={(model) => {
										setPaginationModel(model);
										// FetchAccounts();
									}}
									rowCount={totalcount}
									pageSizeOptions={[15, 25, 50, 100]}
									localeText={{
										MuiTablePagination: {
											labelRowsPerPage: "Records Per Page",
										},
									}}
									disableRowSelectionOnClick={true}
									loading={loading}
									sortModel={sortModel}
									onSortModelChange={handleSortModelChange}
								/>
							</Box>
						</div>
					)}

					{showDeletePopup ? (
						<div className="AccountDelete-popup">
							<div>
								<svg
									onClick={() => setShowDeletePopup(false)}
									className="close-icon"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									width="24"
									height="24"
									viewBox="0 0 50 50"
								>
									<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
								</svg>
							</div>
							<div style={{ width: "330px" }}>
								<p>The Account {emailiddelete} cannot be restored once it is deleted ?</p>

								<button
									style={{
										position: "relative",
										background: "#fff",
										color: "#007bff",
										border: "1px solid #007bff",
										marginRight: "27px",
										right: "-30px",
										top: "40px",
									}}
									onClick={() => setShowDeletePopup(false)}
									className="userButton"
								>
									Cancel
								</button>

								<button
									style={{ position: "relative", right: "-80px", top: "40px" }}
									className="userButton"
									onClick={handleDeleteAccount}
								>
									Delete
								</button>
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
			<Snackbar
				style={{ zIndex: "9999", position: "relative", top: "-500px", width: "500px", right: "-600px" }}
				open={snackbarOpen}
				autoHideDuration={4000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					sx={{ width: "100%" }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default AccountList;
