import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { MenuItem, ListItemText, Button, Box } from "@mui/material";
import { GetAccountsList } from "../../services/AccountService";

const AccountCustomColumnMenuUserItem: React.FC<{
	column: any;
	skip: any;
	top: any;
	OrganizationId: any;
	setTotalcount: any;
	orderByFields: any;
	setModels: any;
	setLoading: any;
	filters: any;
	setFilters: any;
	options: string[];
	onSearch: (value: string[]) => void;
}> = ({
	column,
	skip,
	top,
	OrganizationId,
	setTotalcount,
	orderByFields,
	setModels,
	setLoading,
	filters,
	setFilters,
	options,
	onSearch,
}) => {
	const [searchText, setSearchText] = useState("");
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
	const [filteredOptions, setFilteredOptions] = useState<string[]>(options);

	useEffect(() => {
		// Update filteredOptions when options change, keeping selected options
		if (column !== "AdminDeactivated") {
			setFilteredOptions(options.filter((option) => option.toLowerCase().includes(searchText.toLowerCase())));
		}
	}, [options]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const handleClearSearch = () => {
		setSearchText("");
		setSelectedOptions([]);
	};

	const handleSelectAll = () => {
		const newSelectedOptions = selectedOptions.length === filteredOptions.length ? [] : filteredOptions;
		setSelectedOptions(newSelectedOptions);
	};

	const handleOptionChange = (event: React.SyntheticEvent, newValue: string[]) => {
		setSelectedOptions(newValue);
	};

	const handleSearchClick = () => {
		onSearch(selectedOptions.length ? selectedOptions : [searchText]);
		if (column === "AccountName") {
			const searchValue = selectedOptions.length ? selectedOptions : [searchText];
			const combinedValue = searchValue.join(",");

			const searchCriteria = [
				{
					FieldName: column,
					ElementType: "string",
					Condition: "in",
					Value: combinedValue,
					IsCustomField: false,
				},
			];
			GetAccountsList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, searchCriteria);
		}
	};

	const handleCancelClick = () => {
		setSearchText("");
		setSelectedOptions([]);
		onSearch([]);
		GetAccountsList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
	};

	return (
		<div style={{ padding: "8px 12px", display: "flex", flexDirection: "column" }}>
			<Autocomplete
				multiple
				options={filteredOptions}
				disableCloseOnSelect
				value={selectedOptions}
				onChange={handleOptionChange}
				renderOption={(props, option, { selected }) => (
					<>
						{option === filteredOptions[0] && (
							<MenuItem
								key="select-all"
								onClick={handleSelectAll}
							>
								<Checkbox
									checked={selectedOptions.length === filteredOptions.length}
									indeterminate={selectedOptions.length > 0 && selectedOptions.length < filteredOptions.length}
								/>
								<ListItemText primary="Select All" />
							</MenuItem>
						)}
						<MenuItem {...props}>
							<Checkbox checked={selected} />
							{option}
						</MenuItem>
					</>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						label="Search"
						placeholder="Search..."
						value={searchText}
						onChange={handleSearchChange}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									<IconButton onClick={handleClearSearch}>
										<ClearIcon />
									</IconButton>
								</>
							),
						}}
					/>
				)}
			/>
			<Box
				display="flex"
				justifyContent="flex-end"
				mt={2}
			>
				<Button
					variant="outlined"
					color="primary"
					onClick={handleSearchClick}
				>
					OK
				</Button>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleCancelClick}
					style={{ marginLeft: "8px" }}
				>
					Cancel
				</Button>
			</Box>
		</div>
	);
};

export default AccountCustomColumnMenuUserItem;
