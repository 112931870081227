import React from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { erroricon } from "../../assets/icons/icons"; 

// Define the types for the props
interface LogoutPopupProps {
  onClose: () => void;
  onOk: () => void;
  title: string;
  description: string;
  button1: string;
  button2: string;
}

const LogoutPopup: React.FC<LogoutPopupProps> = ({ onClose, onOk, title, description, button1, button2 }) => {
  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '333px',
          height: '234px',
          borderRadius: '15px',
          padding: '20px',
          textAlign: 'center',
          boxSizing: 'border-box',
          position: 'relative'
        },
      }}
    >
      <Box
        component="img"
        src={erroricon}
        alt="Error Icon"
        sx={{
          width: '44px',
          height: '44px',
          display: 'block',
          margin: '0 auto',
          marginTop: '10px',
        }}
      />
      <DialogTitle sx={{ paddingTop: '10px' }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: '30px',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '12px', overflow: 'hidden' }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 200,
            lineHeight: '20px',
            textAlign: 'center',
            whiteSpace: 'nowrap', 
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {description}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          sx={{
            width: '110px',
            height: '44px',
            padding: '10px 12px',
            gap: '10px',
            borderRadius: '15px 15px',
            border: '1px solid rgba(95, 158, 160, 1)', 
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 200,
            lineHeight: '24px',
            textAlign: 'left',
            color: 'rgba(95, 158, 160, 1)',
              backgroundColor: 'transparent',
              textTransform: 'none', 
          }}
        >
          {button1}
        </Button>
        <Button
          onClick={onOk}
          
          sx={{
            width: '110px',
            height: '44px',
            padding: '10px 12px',
            gap: '4px',
              borderRadius: '15px 15px',
              border: '1px solid rgba(95, 158, 160, 1)',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 200,
            lineHeight: '24px',
            textAlign: 'left',
              color  : 'white',
              backgroundColor: 'rgba(95, 158, 160, 1)',
              textTransform: 'none', 
          }}
        >
          {button2}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutPopup;
