import React, { useState, useEffect } from 'react';
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { Container } from '@mui/material';
const RightSettings = () => {
    const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
    useEffect(() => {
        const unsubscribe = subscribe(setSidebarOpen);
        return () => unsubscribe();
      }, []);
    return (
        <Container maxWidth="xl">

        <div className={`smooth-transition`} style={{marginLeft:sidebarOpen?"170px":""}}>
            <div className='right-setting-header'>In this section, you can toggle certain features on or off. Make sure to read our guides and speak with your team if you have any doubts.</div>
        
            <div className='right-authentication-block'>

                <h3 className="invite-text" style={{ marginLeft: 41 }}>User authentication method</h3>

                <div className="qx-role">
									<div>
										<div className="qx-relative" style={{right:-895,marginTop:-47}}>
											<button
												type="button"
												className="qx-button"
                                aria-disabled="false"
                                style={{maxWidth:153}}
											>
												<span className="role-span">
													<div className="role-div">Email Magic Link</div>
													<div>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 20 20"
															className="tw-fill-current tw-text-slate-800 tw-ml-2"
															data-testid="chevron-down-tci"
															height="16"
															width="16"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
															></path>
														</svg>
													</div>
												</span>
											</button>
											<section className="tw-bg-white tw-rounded-md tw-shadow-lg tw-z-80 tw-mt-2 tw-absolute tw-bottom--4 tw-top-full tw-overflow-hidden tw-transition-all tw-duration-75 tw-transform tw-origin-top-left tw-left-0 tw-w-0 tw-max-h-0 tw-scale-0"></section>
										</div>
									</div>
								</div>


                




            <p className='user-authentication-text'>Choose how users authenticate to access their Chameleon account. Chameleon is password-free, so use magic links, or SSO via Google or a third-party identity provider, e.g. Okta or Azure (paid add-on).</p>

            </div>

            


            
            
            
            
            
            
            
            
            <div className='right-setting-block'>

                <div className="require-authentication-text">Require Multi-Factor Authentication for team</div>
                
                <div className='right-setting-switch'>
                <div className='tooltip'>
                    <div className='cursor-not-allowed'>

                        <div className='pointer-events'>

                                <label className='label' htmlFor="toggle-switch120" aria-label="Toggle  is off">
                                    <input type="checkbox" name="" id="toggle-switch120" className='switch-textfield' aria-disabled="true" aria-pressed="false"></input>
                                    <span className='switch-spanone'>

                                        <span className='switch-spantwo'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="switch-spanfive" data-testid="lock-closed-tci" height="7" width="7">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"></path>
                                            </svg>
                                            </span>
                                            <span className='switch-spanonethree'></span>
                                            <span className='switch-spanfour'>OFF</span>
                                       
                                    </span>
                                    </label>
                                </div>
                        </div>
                        <span className="tooltiptext">Your role doesn't allow you to do this</span>
                    </div>
               </div>
           
            <div className='right-text'>All team members must use multi-factor authentication to sign-in to Chameleon. When this is turned on, all the user sessions (people logged in) will be invalidated and everyone will be logged out. Can only be turned off by Chameleon support.</div>
            </div>

            

            <div className='right-setting-block'>

<div className="require-authentication-text">Display Experiences on mobile</div>

<div className='right-setting-switch'>
<div className='tooltip'>
                    <div className='cursor-not-allowed'>

                        <div className='pointer-events'>

                                <label className='label' htmlFor="toggle-switch120" aria-label="Toggle  is off">
                                    <input type="checkbox" name="" id="toggle-switch120" className='switch-textfield' aria-disabled="true" aria-pressed="false"></input>
                                    <span className='switch-spanone'>

                                        <span className='switch-spantwo'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="switch-spanfive" data-testid="lock-closed-tci" height="7" width="7">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"></path>
                                            </svg>
                                            </span>
                                            <span className='switch-spanonethree'></span>
                                            <span className='switch-spanfour'>OFF</span>
                                       
                                    </span>
                                    </label>
                                </div>
                        </div>
                        <span className="tooltiptext">Your role doesn't allow you to do this</span>
                    </div>
</div>

<div className='right-text'>Allow Experiences to be displayed on mobile devices. Before toggling this on, ensure your Segments are targeting your desired device type, by applying the correct “Device type” segmentation filter. Learn more here.</div>
</div>

            


            

<div className='right-setting-block'>

<div className="require-authentication-text">Enable domains via API</div>

<div className='right-setting-switch'>
<div className='tooltip'>
                    <div className='cursor-not-allowed'>

                        <div className='pointer-events'>

                                <label className='label' htmlFor="toggle-switch120" aria-label="Toggle  is off">
                                    <input type="checkbox" name="" id="toggle-switch120" className='switch-textfield' aria-disabled="true" aria-pressed="false"></input>
                                    <span className='switch-spanone'>

                                        <span className='switch-spantwo'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="switch-spanfive" data-testid="lock-closed-tci" height="7" width="7">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"></path>
                                            </svg>
                                            </span>
                                            <span className='switch-spanonethree'></span>
                                            <span className='switch-spanfour'>OFF</span>
                                       
                                    </span>
                                    </label>
                                </div>
                        </div>
                        <span className="tooltiptext">Your role doesn't allow you to do this</span>
                    </div>
</div>

<div className='right-text'>Enable and verify domains and subdomains at scale using the Chameleon API. This feature is handy if you have multiple subdomains e.g., customer123.yourdomain.com, and you want to enable Experiences automatically. New subdomains are disabled by default as a security measure. Learn more about domains.</div>
</div>

            




<div className='right-setting-block'>

<div className="require-authentication-text">Allow HTML in Steps</div>

<div className='right-setting-switch'>
<div className='tooltip'>
                    <div className='cursor-not-allowed'>

                        <div className='pointer-events'>

                                <label className='label' htmlFor="toggle-switch120" aria-label="Toggle  is off">
                                    <input type="checkbox" name="" id="toggle-switch120" className='switch-textfield' aria-disabled="true" aria-pressed="false"></input>
                                    <span className='switch-spanone'>

                                        <span className='switch-spantwo'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="switch-spanfive" data-testid="lock-closed-tci" height="7" width="7">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"></path>
                                            </svg>
                                            </span>
                                            <span className='switch-spanonethree'></span>
                                            <span className='switch-spanfour'>OFF</span>
                                       
                                    </span>
                                    </label>
                                </div>
                        </div>
                        <span className="tooltiptext">Your role doesn't allow you to do this</span>
                    </div>
</div>

<div className='right-text'>Permit text content within Experiences to be parsed or read as HTML. This allows scripts to be executed in content fields (e.g. within the body of a Tour Step). Enabling this allows untested scripts to be executed inside your product, which could present a security risk.</div>
</div>

            


<div className='right-setting-block' style={{height:85}}>

<div className="require-authentication-text">Disable Run Code option</div>

<div className='right-setting-switch'>
<div className='tooltip'>
                    <div className='cursor-not-allowed'>

                        <div className='pointer-events'>

                                <label className='label' htmlFor="toggle-switch120" aria-label="Toggle  is off">
                                    <input type="checkbox" name="" id="toggle-switch120" className='switch-textfield' aria-disabled="true" aria-pressed="false"></input>
                                    <span className='switch-spanone'>

                                        <span className='switch-spantwo'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="switch-spanfive" data-testid="lock-closed-tci" height="7" width="7">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"></path>
                                            </svg>
                                            </span>
                                            <span className='switch-spanonethree'></span>
                                            <span className='switch-spanfour'>OFF</span>
                                       
                                    </span>
                                    </label>
                                </div>
                        </div>
                        <span className="tooltiptext">Your role doesn't allow you to do this</span>
                    </div>
</div>

<div className='right-text'>Turning this on will remove the ability to run code as an option from a Tour/Microsurvey button or Launcher item. Learn more about custom code scripts.</div>
</div>

            



<div className='right-setting-block'>

<div className="require-authentication-text">Display Experiences in RTL</div>

<div className='right-setting-switch'>
<div className='tooltip'>
                    <div className='cursor-not-allowed'>

                        <div className='pointer-events'>

                                <label className='label' htmlFor="toggle-switch120" aria-label="Toggle  is off">
                                    <input type="checkbox" name="" id="toggle-switch120" className='switch-textfield' aria-disabled="true" aria-pressed="false"></input>
                                    <span className='switch-spanone'>

                                        <span className='switch-spantwo'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="switch-spanfive" data-testid="lock-closed-tci" height="7" width="7">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"></path>
                                            </svg>
                                            </span>
                                            <span className='switch-spanthree'></span>
                                            <span className='switch-spanfour'>OFF</span>
                                       
                                    </span>
                                    </label>
                                </div>
                        </div>
                        <span className="tooltiptext">Your role doesn't allow you to do this</span>
                    </div>
</div>

<div className='right-text'>If your application is using a Right-to-Left language, use this toggle to ensure Chameleon Experiences display in the same direction. Keep in mind that this will reverse the positioning settings: for example, setting a position to the left will actually align it to the right.</div>
</div>

        
        </div>
        </Container>
    
      
    );
  };
  

export default RightSettings