import React, { useState, useEffect } from "react";
import {
	Dashboard,
	Audience,
	Tours,
	Announcements,
	Tooltips,
	Banners,
	Checklist,
	Surveys,
	Hotspots,
	Settings,
	Auditlog,
	Filemanagement
} from "../../assets/icons/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Popup from "../common/Popup";
import ButtonBase from '@mui/material/ButtonBase';
import { isSidebarOpen, setSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { useRtl } from "../../RtlContext";
import { Divider } from "@mui/material";

interface SideMenuProps {
	selectedLanguageProp: string;
}

const SideMenu: React.FC<SideMenuProps> = ({ selectedLanguageProp }) => {
	const [activeItem, setActiveItem] = useState<string>("");
	const [openPopup, setOpenPopup] = useState(false);
	const [sidebarOpen, setLocalSidebarOpen] = useState(isSidebarOpen());
	const [translatedTitles, setTranslatedTitles] = useState<{ [key: string]: string }>({});

	const navigate = useNavigate();
	const location = useLocation();

	const { isRtl, toggleRtl } = useRtl();

	const themeWithDirection = createTheme({
		direction: isRtl ? "rtl" : "ltr",
	});

	useEffect(() => {
		const unsubscribe = subscribe(setLocalSidebarOpen);
		return () => unsubscribe();
	}, []);

	const MenuClick = (title: string) => {
		if (title.toLowerCase() === "hotspots") {
			// Do nothing for disabled item
			return;
		}
		setActiveItem(title);
		switch (title.toLowerCase()) {
			case "dashboard":
				return navigate("/dashboard");
			case "audience":
				return navigate("/audience");
			case "tours":
				return navigate("/tours");
			case "announcements":
				return navigate("/announcements");
			case "tooltips":
				return navigate("/tooltips");
			case "banners":
				return navigate("/banners");
			case "checklists":
				return navigate("/checklists");
			case "surveys":
				return navigate("/surveys");
			case "hotspots":
				return navigate("/hotspots");
			case "settings":
				return navigate("/settings/accounts");
			case "auditlogs":
				return navigate("/auditlog");
			case 'filemanagement':
				return navigate("/filelist");
			default:
				return navigate("/");
		}
	};

	const toggleSidebar = () => {
		setLocalSidebarOpen(!sidebarOpen);
		setSidebarOpen(!sidebarOpen);
	};

	if (location.pathname === "/Builder") {
		return null;
	}

	const menuItems = [
		{ icon: Dashboard, title: "Dashboard" },
		{ icon: Audience, title: "Audience" },
		{ icon: Tours, title: "Tours" },
		{ icon: Announcements, title: "Announcements" },
		{ icon: Tooltips, title: "Tooltips" },
		{ icon: Banners, title: "Banners" },
		{ icon: Checklist, title: "Checklists" },
		{ icon: Surveys, title: "Surveys" },
		{ icon: Hotspots, title: "Hotspots", disabled: true },
		{ icon: Auditlog, title: "Auditlogs" },
		{ icon: Filemanagement, title: "FileManagement" },
		{ icon: Settings, title: "Settings" },


	];

	return (
		<ThemeProvider theme={themeWithDirection}>
			<CssBaseline />
			<Box sx={{ display: "flex" }}>
				<Drawer
					variant="persistent"
					anchor={isRtl ? "right" : "left"}
					open={sidebarOpen}
					sx={{
						"& .MuiDrawer-paper": {
							width: 240,
							boxSizing: "border-box",
							direction: isRtl ? "rtl" : "ltr",
						},
					}}
				>
					<List style={{ marginTop: '40px' }}>
						{menuItems.map(({ icon, title, disabled }, index) => (
							<React.Fragment key={title}>
								<ButtonBase
									onClick={() => MenuClick(title)}
									sx={{
										marginLeft: '10px',
										marginTop: '-3px',
										width: "220px",
										height: "48px",
										padding: "12px 23px",
										gap: "-5px",
										borderRadius: "6px",
										backgroundColor: activeItem === title ? "rgba(95, 158, 160, 1)" : "transparent",
										"&:hover": {
											opacity: 1,
										},
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-start",
										cursor: disabled ? "not-allowed" : "pointer",
										opacity: disabled ? 0.5 : 1,
										whiteSpace: 'nowrap'
									}}
									disabled={disabled}
								>
									<ListItemIcon sx={{ minWidth: "40px", color: activeItem === title ? "#FFFFFF" : "#202224" }}>
										<img
											src={icon}
											alt={title}
											style={{ filter: activeItem === title ? 'invert(1)' : 'none' }}
										/>
									</ListItemIcon>
									<Box>
										<Typography
											sx={{
												width: "81px",
												height: "21px",
												fontFamily: "Poppins, sans-serif",
												fontWeight: 500,
												fontSize: "13px",
												lineHeight: "19px",
												letterSpacing: "0.3px",
												textAlign: "left",
												color: activeItem === title ? "#FFFFFF" : "#202224",
												ml: "-8px",
											}}
										>
											{translatedTitles[title] || title}
										</Typography>
										{disabled && (
											<Typography
												sx={{
													fontFamily: "Poppins, sans-serif",
													fontWeight: 400,
													fontSize: "12px",
													lineHeight: "18px",
													color: "#b0b0b0",
													marginLeft: '-8px'
												}}
											>
												Coming soon
											</Typography>
										)}
									</Box>
								</ButtonBase>
								{index === 1 && <Divider sx={{ marginX: 2 }} />}
								{index === 9 && <Divider sx={{ marginX: 2 }} />}
							</React.Fragment>
						))}
					</List>
				</Drawer>
				<IconButton
					onClick={toggleSidebar}
					sx={{ ml: sidebarOpen ? (isRtl ? -30 : 30) : 1 }}
				>
					<MenuIcon />
				</IconButton>
				{openPopup && (
					<Popup
						onClose={() => setOpenPopup(false)}
						onOk={() => setOpenPopup(false)}
						type="Apply"
						title="Log out from QuickAdapt"
						description="Do you really want to logout?"
						button1="Cancel"
						button2="Logout"
					/>
				)}
			</Box>
		</ThemeProvider>
	);
};


export default SideMenu;
