import React, { useState, useEffect, useRef } from 'react';
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import {
  Container, Typography, Button,
  TextField, IconButton, InputAdornment,
  RadioGroup, FormControlLabel, Radio,
  FormControl, FormLabel,
  Avatar, Grid, Box, FormGroup, Switch, MenuItem
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CameraAlt, Edit } from '@mui/icons-material';
import { UpdateProfile, GetUserDetail } from "../../services/ProfileSettingPageService";
import { useLocation } from 'react-router-dom';
import { avatar } from '../../assets/icons/icons';
import ChangePasswordPopup from '../adminMenu/changepassword';
let profilePhoto: any;

const ProfileSettings = ({ selectedLanguageProp }: { selectedLanguageProp: any }) => {
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [passwordEditable, setPasswordEditable] = useState(false);
  const [contactEditable, setContactEditable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isChangePasswordPopupOpen, setChangePasswordPopupOpen] = useState(false);

  const [profileData, setProfileData] = useState({
    UserId: '',
    FirstName: '',
    LastName: '',
    UserName: '',
    EmailId: '',
    Password: '',
    confirmpassword: '',
    ContactNumber: '',
    OrganizationId: '',
    ProfilePhoto: '',
    UserType: '',
    Gender: '',
    DateofBirth: '',
    TimeZone: '',
    LoginType: '',
    DateOfBirth: '',
    IsBlocked: false
  });

  useEffect(() => {
    const unsubscribe = subscribe(setSidebarOpen);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetailsArray = await GetUserDetail();
        if (userDetailsArray.length > 0) {
          const userDetails = userDetailsArray[9];
          setProfileData({
            ...profileData,
            ...userDetails,
          });
          if (userDetails.ProfilePhoto) {
            setSelectedImage(dataURLtoFile(userDetails.ProfilePhoto, 'profilePhoto.png'));
            profilePhoto = userDetails.ProfilePhoto;
          }
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, []);

  const handleCameraClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const userType = localStorage.getItem("userType");
  const location = useLocation();
  const labelStyles = {
    position: 'absolute' as 'absolute',
    top: '-6px',
    left: '1px',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    textAlign: 'center',
    color: 'rgba(34, 34, 34, 1)',
    padding: '0 4px',
  };


  const textFieldStyles = {
    width: '365.67px',
    height: '45px',
    borderRadius: '6px 6px',
    border: '1px solid rgba(220, 221, 225, 1)',
    opacity: 0.9,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(220, 221, 225, 0.1)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(220, 221, 225, 0.1)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(220, 221, 225, 0.1)',
      },
      '& input': {
        padding: '11px 12px',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'left',
      },
    },
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
      toBase64(event.target.files[0]).then(base64 => {
        setProfileData({ ...profileData, ProfilePhoto: base64 });
      });
    }
  };

  const toBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  const dataURLtoFile = (dataurl: string, filename: string) => {
    let arr = dataurl.split(',');
    let mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      throw new Error("Invalid data URL format");
    }
    let mime = mimeMatch[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const validatePhoneNumber = (number: string): boolean => {
    const phoneNumberPattern = /^[0-9]*$/;
    return phoneNumberPattern.test(number);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!validatePhoneNumber(profileData.ContactNumber)) {
      console.error('Invalid phone number');
      return;
    }
    try {
      const response = await UpdateProfile(profileData);
      console.log('Profile updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
    if (selectedImage && selectedImage !== undefined) {
      profilePhoto = selectedImage;
    }
  };


  const handlePasswordChangeClick = () => {
    setChangePasswordPopupOpen(true); // Open the popup
  };

  const closeChangePasswordPopup = () => {
    setChangePasswordPopupOpen(false); // Close the popup
  };

  const handlePasswordBlur = () => {
    if (profileData.Password) {
      // setPasswordEditable(false);
    }
  };

  const handleContactChangeClick = () => {
    setContactEditable(true);
  };

  const handleContactBlur = () => {
    if (profileData.ContactNumber) {
      setContactEditable(false);
    }
  };

  return (
    <div className='.page-container'>
     <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end', // Move all content to the right
    marginTop: '35px',
    marginLeft: sidebarOpen ? '285px' : '3%',
  }}
>
  <Typography
    variant="h6"
    sx={{
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '48px',
      letterSpacing: '-0.114px',
      textAlign: 'left',
      marginRight: 'auto', // Ensure the title stays on the left
    }}
  >
    User Profile
  </Typography>

  <Button
    variant="contained"
    color="primary"
    type="submit"
    style={{
      cursor: 'pointer',
      fontFamily: 'Poppins',
      fontSize: '14px',
      backgroundColor: 'rgba(165, 195, 197, 1)',
      color: 'white',
      fontWeight: '500',
      border: '0px solid #e5e5e5',
      borderRadius: '15px',
      padding: '6px 18px',
      textTransform: 'none',
      marginRight: '-50px', // Optional: add margin on the right side
    }}
    disabled={profileData.FirstName === "" && profileData.ContactNumber === ""}
  >
    <Typography
      variant="body2"
      sx={{ fontFamily: 'Poppins', fontWeight: '500', color: 'white' }}
    >
      Save Changes
    </Typography>
  </Button>
</Box>


      <Box
        className={`smooth-transition`}
        sx={{
          marginLeft: sidebarOpen ? "21%" : "3%",
          marginTop: '25px',
          background: "white",
          borderRadius: "8px",
          padding: "20px",
          boxSizing: "border-box",
          width: sidebarOpen ? "calc(88% - 60px)" : "calc(100% - 0px)", 
          height: "calc(90vh - 90px)",
          overflowY: "auto",
          minHeight: "calc(90vh - 90px)",
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(95, 158, 160, 0.8)',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(95, 158, 160, 1)',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent', 
          },
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: -20,
            zIndex: 1,
            background: 'white',
            padding: '18px 18px',
            marginLeft: '-10px',
            marginRight: '-20px',

          }}
        >
          <Typography
            variant="h4"
            className='profile-text'
            sx={{
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '21px',
              textAlign: 'left',
            }}
          >
            Profile
          </Typography>
        </Box>


        <form noValidate style={{ marginLeft: "95px" }} onSubmit={handleSubmit}>
          <Box
            sx={{
              width: '90px',
              height: '90px',
              position: 'relative',
              top: '30px',
              left: '-95px',
              background: 'rgba(246, 238, 238, 1)',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {selectedImage ? (
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Profile"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <img
                src={avatar}
                alt="Profile"
                style={{ width: '50px', height: '50px', opacity: 0.5 }}
              />
            )}

            <IconButton
              sx={{
                position: 'absolute',
                bottom: '10px',
                right: '-10px',
                backgroundColor: 'rgba(24, 20, 243, 1)',
                padding: '5px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                width: '25px',
                height: '25px',
              }}
              onClick={handleCameraClick}
            >
              <Edit sx={{ color: 'white', width: '15px', height: '15px' }} />
            </IconButton>

            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Box>



          <Grid container spacing={2} sx={{ marginTop: "55px", marginLeft: '-110px' }}>
            <Grid item xs={6} md={4.7}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography sx={labelStyles}>First Name</Typography>
                <TextField
                  name="FirstName"
                  value={profileData.FirstName}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  sx={textFieldStyles}
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={4.7}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography sx={labelStyles}>Last Name</Typography>
                <TextField
                  name="LastName"
                  value={profileData.LastName}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  sx={textFieldStyles}
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={4.7}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography sx={labelStyles}>Email</Typography>
                <TextField
                  name="EmailId"
                  value={profileData.EmailId}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  sx={textFieldStyles}
                  required
                  disabled
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={4.7}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography sx={labelStyles}>Phone Number</Typography>
                <TextField
                  name="ContactNumber"
                  value={profileData.ContactNumber}
                  onChange={handleInputChange}
                  onBlur={handleContactBlur}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  sx={textFieldStyles}
                  required
                  inputProps={{
                    type: 'tel',
                    pattern: '[0-9]*'
                  }}
                />
              </Box>
            </Grid>


            <Grid item xs={4.7}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography sx={labelStyles}>Gender</Typography>
                <TextField
                  select
                  value={profileData.Gender}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  sx={textFieldStyles}
                  required
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={6} md={4.7}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography sx={labelStyles}>Date of Birth</Typography>
                <TextField
                  name="DateOfBirth"
                  type="date"
                  value={profileData.DateOfBirth}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  sx={textFieldStyles}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>


            <Grid item xs={6.7}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography sx={labelStyles}>Password</Typography>
                <TextField
                  name="Password"
                  type={showPassword ? "text" : "password"}
                  value={profileData.Password}
                  onChange={handleInputChange}
                  // onBlur={handlePasswordBlur}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  sx={textFieldStyles}
                  style={{ width: '555px' }}
                  required
                  // disabled={!passwordEditable}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>

             <Grid style={{ marginTop: '30px' }}>
        <Button
          onClick={handlePasswordChangeClick}
          color="primary"
          sx={{
            marginLeft: 6,
            width: '172px',
            height: '44px',
            padding: '10px 12px',
            gap: '10px',
            borderRadius: '15px 15px',
            border: '1px solid transparent',
            borderColor: 'rgba(95, 158, 160, 1)',
            backgroundColor: 'white',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '24px',
              textAlign: 'centre',
              color: 'rgba(95, 158, 160, 1)',
              textTransform: 'none'
            }}
          >
            Change Password
          </Typography>
        </Button>
      </Grid>

      {/* Include the popup */}
      <ChangePasswordPopup
        open={isChangePasswordPopupOpen}
        onClose={closeChangePasswordPopup}
      />
  
          </Grid>


          {/* <div style={{ marginTop: '0px' }}>
            <label className='class-labels'>Two Factor Authentication</label>
            <FormGroup sx={{ width: "34px" }}>
              <FormControlLabel control={<Switch />} label="disabled" />
            </FormGroup>
          </div> */}
        </form>
      </Box>
    </div>
  );
};

export default ProfileSettings;
export { profilePhoto };
