import React from 'react';
import {useLocation } from 'react-router-dom';
import OrgAdminMenu from '../adminMenu/AdminMenu';

import Settings from '../settings/Settings';
import RegistrationPage from '../RegistrationPage/RegistrationPage';

const Layout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();

  // Check if the current location is under '/settings'
  const isSettingsPage = location.pathname.startsWith("/settings/") && location.pathname.substring("/settings/".length).length > 0;
  const userType = localStorage.getItem("userType");
  return (
    <div>
      {/* Render OrgAdminMenu only if it's not the settings page */}
      {location.pathname==="/Registration"?(<RegistrationPage/>):(<OrgAdminMenu />)}
      
      {/* Render SettingsComponent only if it's the settings page */}
      {/* {isSettingsPage && userType==="admin" && <Settings />} */}
      <main>{children}</main>
    </div>
  );
};

export default Layout;
