// src/AuthProvider.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { User, UserManager } from 'oidc-client-ts';
import userManager from './UseAuth';


interface AuthContextType {
  user: User | null;
  signIn: () => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    userManager.getUser().then(setUser);
    userManager.events.addUserLoaded(setUser);
    userManager.events.addUserUnloaded(() => setUser(null));
  }, []);

  const signIn = () => userManager.signinRedirect();
  const signOut = () => userManager.signoutRedirect();

  return (
    <AuthContext.Provider value={{ user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  else {
      const user = context?.user;
      if (user && user?.access_token)
      {
        localStorage.setItem("access_token", user?.access_token);     
      }
      else
      {
        console.log("User not exists ");      
      }
  }
  return context;
};
