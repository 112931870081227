import React, { useState, useEffect, startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { Popover, Typography, Button, Avatar, Box } from '@mui/material';
import SideMenu from "./sideMenu";
import { quickadopt } from "../../assets/icons/icons";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Popup from "../common/Popup";
import { FormControl, InputLabel, InputAdornment, IconButton, MenuItem, Grid } from "@mui/material";
import Select from "@mui/material/Select";
import LanguageIcon from '@mui/icons-material/Language';
import { translateText } from "../Translator"; // Assuming translateText function is defined in translator.ts or similar
import Translater from "../Multilingual";
import { useLocation } from "react-router-dom";
import { LanguageProvider } from "../LanguageContext";
import ProfileSettings from "../settings/ProfileSettings";
import Settings from "../settings/Settings";
import AccountSettings from "../settings/AccountSettings";
import { getLanguages } from "../../services/MultilingualService";
import { profilePhoto } from "../settings/ProfileSettings";
import { isSidebarOpen, setSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { Sidebar } from "../common/Sidebar";
import PageWrapper from "../pagewrapper";
import { Feedback, Logout, settings } from "../../assets/icons/icons";
import TeamSettings from "../settings/TeamSettings";
import RightSettings from "../settings/RightSettings";
import UserList from "../user/UserList";
import LogoutPopup from "./logoutpopup";
import AccountList from "../account/AccountList";
import userManager from '../auth/UseAuth';
export interface Language {
	LanguageId: string;
	Language: string;
	LanguageCode: string;
	FlagIcon: string;
}

const labels: any[] = [
	{ id: 1, name: 'Rights' },
	{ id: 2, name: 'Profile' },
	{ id: 3, name: 'Dispatch Items' },
	{ id: 4, name: 'Organization' },
	{ id: 5, name: 'Items' },
	{ id: 6, name: 'First Name' },
	{ id: 7, name: 'Home' },
	{ id: 8, name: 'Journeys' },
	{ id: 9, name: 'Themes' },
	{ id: 10, name: 'Hotspots' },
	{ id: 11, name: 'CheckLists' },
	{ id: 12, name: 'Multilingual' },
	{ id: 13, name: 'Logout' },
	{ id: 14, name: 'Settings' },
	{ id: 15, name: 'File Management' },
	{ id: 16, name: 'user' },
	{ id: 17, name: 'Audit Log' },
	{ id: 18, name: 'Notifications' },
	{ id: 19, name: 'HelpCenter' },
	{ id: 20, name: 'Share Feedback' },
	{ id: 21, name: 'Dispatch Items' },
	{ id: 22, name: 'Organization' },
	{ id: 23, name: 'Teams' },
	{ id: 24, name: 'Language Translation' },
];

const OrgAdminMenu: React.FC = () => {
	const [openMenu, setOpenMenu] = useState(false);
	const [userType, setUserType] = useState("");
	const [organization, setOrganization] = useState({
		OrganizationId: "01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863",
		OrganizationName: "QUIXY",
		TimeZone: "Asia/Kolkata",
	});
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
	const [openPopup, setOpenPopup] = useState(false);
	//const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [sidebarLocalOpen, setLocalSidebarOpen] = useState(isSidebarOpen());
	const navigate = useNavigate();

	const [toLanguage, setToLanguage] = useState<string>("en"); // Default language selection
	const [translatedLabels, setTranslatedLabels] = useState<string[]>([]);
	const location = useLocation();
	const [languages, setLanguages] = useState<Language[]>([]); // State to hold languages array
	const [loading, setLoading] = useState(true); // Loading state
	const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
	useEffect(() => {
		const userType = localStorage.getItem("userType") || "admin";
		const storedOrganizationString = localStorage.getItem("organization");
		const storedOrganization = storedOrganizationString
			? JSON.parse(storedOrganizationString)
			: {
				OrganizationId: "01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863",
				OrganizationName: "QUIXY",
				TimeZone: "Asia/Kolkata", // Default datetime value
			};
		setUserType(userType);
		setOrganization(storedOrganization);
		fetchLanguages();
	}, []);

	useEffect(() => {
		const storedSelectedLanguages = localStorage.getItem("selectedLanguages");
		if (storedSelectedLanguages) {
			setSelectedLanguages(JSON.parse(storedSelectedLanguages));
		}
	}, []);
	console.log(selectedLanguages, "In admin Menu Selected Languages");

	const fetchLanguages = async () => {
		try {
			const data = await getLanguages(); // Call the getLanguages function from API.tsx
			setLanguages(data);
		} catch (error) {
			console.error("Error fetching languages:", error);
		} finally {
			setLoading(false);
		}
	};

	const getLanguageName = (languageCode: string): string => {
		const language = languages.find((lang) => lang.LanguageCode === languageCode);
		return language ? language.Language : "Unknown";
	};

	const handleToLanguageChange = async (e: any) => {
		const newToLanguage = e.target.value as string;
		setToLanguage(newToLanguage);
	};

	const handleMenuClick = () => {
		setOpenMenu((prev) => !prev);
	};

	useEffect(() => {
		const unsubscribe = subscribe(setLocalSidebarOpen);
		return () => unsubscribe();
	}, []);

	const handleHomeClick = () => {
		startTransition(() => {
			navigate("/");
		});
	};

	const handleTogglePopover = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl((prev) => (prev ? null : event.currentTarget));
	};

	const handleOrgClick = () => {
		startTransition(() => {
			navigate("/organizations");
		});
	};

	const handleAccountClick = () => {
		startTransition(() => {
			navigate("/accounts");
		});
	};

	const handleGuideClick = () => {
		startTransition(() => {
			navigate("/guide");
		});
	};

	const handleAccSeetingsClick = () => {
		startTransition(() => {
			navigate("/settings/account");
		});
	};

	const handleTeamClick = () => {
		startTransition(() => {
			navigate("/settings/team");
		});
	};

	const handleThemesClick = () => {
		startTransition(() => {
			navigate("/theme");
		});
	};

	const handleBillingClick = () => {
		startTransition(() => {
			navigate("/settings/billing");
		});
	};

	const handleInstallClick = () => {
		startTransition(() => {
			navigate("/settings/install");
		});
	};

	const handlenotifyClick = () => {
		startTransition(() => {
			navigate("/notifications");
		});
	};

	const handleProfileClick = () => {
		startTransition(() => {
			navigate("/profilesettings");
		});
		setAnchorEl(null);
	};

	const handleClick = (path: string) => {
		switch (path) {
			case "user":
				return navigate("/user");
			case "account":
				return navigate("/settings/account");
			case "settings":
				return navigate("/settings");
			case "audit":
				return navigate("/superadmin/audit");
			case "organization":
				return navigate("/superadmin/organizations");
			case "multilingual":
				return navigate("/superadmin/Multilingual");
			default:
				return navigate("/");
		}
	};

	useEffect(() => {
		if (userType !== "") {
			localStorage.setItem("userType", userType);
		} else {
			localStorage.setItem("userType", "admin");
			//localStorage.setItem("OrganizationId", "01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863");
		}
		if (organization.OrganizationId !== "") {
			localStorage.setItem("organization", JSON.stringify(organization));
		} else {
			localStorage.setItem("organization", JSON.stringify(organization));
		}
	}, [userType]);

	console.log(toLanguage, "Admin Menu");
	console.log(getLanguageName(toLanguage), "Admin Menu");

	const handleQuickAdoptClick = () => {
		startTransition(() => {
			navigate("/");
		});
	};

	const handleLogoutClick = () => {
		setOpenPopup(true);
		setAnchorEl(null);
	};
	const toggleSidebar = () => {
		setSidebarOpen(!sidebarLocalOpen);
	};
	return (
		<div>
			<div className="banner">
				<div>
					<div className="left-menu">
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={1}
								sx={{ marginTop: "30px", zIndex: "99999" }}
							>
								<div
									style={{ cursor: "pointer", marginTop: "-7px", marginLeft: "9px" }}
									onClick={(e) => {
										e.stopPropagation();
										toggleSidebar();
									}}
								>
									{sidebarLocalOpen ? (
										<MenuOpenIcon sx={{ fontSize: "30px", color: "black" }} />
									) : (
										<MenuIcon sx={{ fontSize: "30px", color: "black" }} />
									)}
								</div>
							</Grid>
							<Grid
								item
								xs={1}
								sx={{ marginTop: "30px", marginRight: "10px" }}
							>
								<Typography
									onClick={handleQuickAdoptClick}
									sx={{
										cursor: "pointer",
										marginLeft: "40px",
										marginTop: "1px",
										color: "rgba(34, 34, 34, 1)",
										fontFamily: "Syncopate",
										fontSize: "14px",
										fontWeight: 700,
										lineHeight: "14.57px",
										letterSpacing: "0.3px",
										textAlign: "left",

									}}
								>
									QUICKADOPT
								</Typography>
							</Grid>

						</Grid>
						<div></div>
					</div>

					{openMenu && userType.toLocaleLowerCase() === "superadmin" ? (
						<div className="button-container">
							<button
								style={{ marginLeft: 59 }}
								onClick={() => handleClick("organization")}
								className="banner-button"
							>
								Organization
							</button>
							<button
								style={{ cursor: "pointer" }}
								onClick={() => handleClick("audit")}
								className="banner-button"
							>
								Audit
							</button>

							<button
								onClick={() => handleClick("multilingual")}
								className="banner-button"
							>
								Language Settings
							</button>
						</div>
					) : null}
				</div>
				<div className="right-menu">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						className="menu-icon"
						data-testid="cog-tci"
						onClick={handleMenuClick}
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.062 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.532 1.532 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.533 1.533 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
						></path>
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						className="menu-icon"
						data-testid="bell-tci"
					>
						<path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
					</svg>
					{location.pathname !== "/Multilingual" && userType !== "superadmin" ? (
						<div style={{ width: 123, height: 27, marginTop: 10 }}>
							<Box>
								<FormControl
									variant="standard"
									style={{ marginTop: -20, marginLeft: -16, padding: 0 }}
								>
									<InputLabel id="demo-simple-select"></InputLabel>
									<Select
										style={{ marginLeft: 10, backgroundColor: "gray" }}
										labelId="demo-simple-select"
										id="demo-simple"
										defaultValue="English"
										value={toLanguage}
										onChange={handleToLanguageChange}
										startAdornment={
											<InputAdornment
												style={{ marginLeft: 13 }}
												position="start"
											>
												<IconButton style={{ marginLeft: -5 }}>
													<LanguageIcon sx={{ color: "rgba(34, 34, 34, 1)" }} />
												</IconButton>
											</InputAdornment>
										}
									>
										{languages.map((language) => (
											<MenuItem
												key={language.LanguageId}
												value={language.LanguageCode}
											>
												{language.Language}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</div>
					) : null}
					<div
						className="rounded-circle"
						onClick={handleTogglePopover}
					>
						<span style={{ display: "flex", marginRight: "0.5rem !important" }}>
							<span>
								<div className="round-div">
									<span className="round-span">NA</span>
								</div>
							</span>
						</span>
					</div>
					<div className="profilepopup">
						<Popover
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={handleTogglePopover}
							anchorOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							PaperProps={{
								style: {
									marginTop: '32px',
								},
							}}
							sx={{
								"& .MuiPaper-root": {
									width: "195px",
									height: "auto",
									borderRadius: "7px",
									padding: "10px 12px",
									backgroundColor: "#ffffff",
									boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
									zIndex: 9999,
								},
							}}
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								p={1}  // Adjust padding to remove extra gap
							>
								<Box
									width="100%"
								>
									<Button
										fullWidth
										onClick={handleProfileClick}
										sx={{
											width: '171px',
											height: '44px',
											padding: '10px 0',
											gap: '10px',
											justifyContent: 'flex-start',
											textTransform: 'none',
										}}
									>
										<img
											src={Feedback}
											alt="Feedback Icon"
											style={{ width: '18px', height: '18px', marginRight: '8px' }}
										/>
										<Typography
											sx={{
												fontFamily: 'Poppins',
												fontSize: '14px',
												fontWeight: 400,
												lineHeight: '21px',
												letterSpacing: '0.3px',
												textAlign: 'left',
												color: '#202224',
											}}
										>
											Share Feedback
										</Typography>
									</Button>
									<Button
										fullWidth
										onClick={handleProfileClick}
										sx={{
											width: '171px',
											height: '44px',
											padding: '10px 0',
											gap: '10px',
											justifyContent: 'flex-start',
											textTransform: 'none',
										}}
									>
										<img
											src={settings}
											alt="Profile Icon"
											style={{ width: '18px', height: '18px', marginRight: '8px' }}
										/>
										<Typography
											sx={{
												fontFamily: 'Poppins',
												fontSize: '14px',
												fontWeight: 400,
												lineHeight: '21px',
												letterSpacing: '0.3px',
												textAlign: 'left',
												color: '#202224',
											}}
										>
											Profile Settings
										</Typography>
									</Button>
									<Button
										fullWidth
										onClick={handleLogoutClick}
										sx={{
											width: '171px',
											height: '44px',
											padding: '10px 0',
											gap: '10px',
											justifyContent: 'flex-start',
											textTransform: 'none',
										}}
									>
										<img
											src={Logout}
											alt="Logout Icon"
											style={{ width: '18px', height: '18px', marginRight: '8px' }}
										/>
										<Typography
											sx={{
												fontFamily: 'Poppins',
												fontSize: '14px',
												fontWeight: 400,
												lineHeight: '21px',
												letterSpacing: '0.3px',
												textAlign: 'left',
												color: '#202224',
											}}
										>
											Logout
										</Typography>
									</Button>
								</Box>
							</Box>
						</Popover>
					</div>
				</div>
			</div>
			{userType.toLocaleLowerCase() === "admin" ? <SideMenu selectedLanguageProp={toLanguage} /> : null}
			{openPopup ? (
				<LogoutPopup
					onClose={() => setOpenPopup(false)}
					onOk={() => {
						userManager.signoutRedirect();
						setOpenPopup(false);
					}}
					title="Confirmation"
					description="Are you sure you want to logout?"
					button1="Cancel"
					button2="Logout"
				/>
			) : null}

			{location.pathname === "/Multilingual" && userType.toLocaleLowerCase() === "admin" ? (
				<LanguageProvider>
					<Translater selectedLanguageProp={toLanguage} />
				</LanguageProvider>
			) : (
				""
			)}
			{location.pathname === "/settings/accounts" && userType === "admin" ? (
				<PageWrapper>
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<div style={{ width: '15%' }}>
							<Settings />
						</div>
						<div style={{ width: '85%' }}>
							<AccountList />
						</div>
					</div>
				</PageWrapper>
			) : null}

			{location.pathname === "/profilesettings" && userType === "admin" ? (
				<PageWrapper>
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						{/* <div style={{ width: '15%' }}>
			 <Settings />
		   </div> */}
						<div style={{ width: '95%' }}>
							<ProfileSettings selectedLanguageProp={getLanguageName(toLanguage)} />
						</div>
					</div>
				</PageWrapper>
			) : null}
			{location.pathname === "/settings/team" && userType === "admin" ? (

				<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<PageWrapper>
						<Box sx={{ width: '15%' }}>
							<Settings />
						</Box>

						<Box
							sx={{
								width: '100%',
								marginLeft: '1%',
								backgroundColor: 'white',
								borderRadius: '6px',
								padding: '20px',
								boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
							}}
						>
							<UserList />
						</Box>

					</PageWrapper>
				</Box>

			) : null}

			{location.pathname === "/settings/user" && userType === "admin" ? (
				<PageWrapper>
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<div style={{ width: '15%' }}>
							<Settings />
						</div>
						<div style={{ width: '85%', marginLeft: '15%' }}>
							<TeamSettings />
						</div>
					</div>
				</PageWrapper>
			) : null}
			{location.pathname === "/settings/rights" && userType === "admin" ? (
				<PageWrapper>
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<div style={{ width: '15%' }}>
							<Settings />
						</div>
						<div style={{ width: '85%', marginLeft: '15%' }}>
							<RightSettings />
						</div>
					</div>
				</PageWrapper>
			) : null}
		</div>
	);
};

export default OrgAdminMenu;
