import React, { useState, useEffect, useRef } from 'react';
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import {
    Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, IconButton, Checkbox, ListItemText, OutlinedInput, SelectChangeEvent, Autocomplete, Container, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Alert
} from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridColumnMenuProps } from '@mui/x-data-grid';
import CustomColumnMenu from '../CustomColumnMenu';
import { getUserRoles, GetOrganizationUsers, saveUserRoles, GetAllUserRolesbyOrganizationId, GetAllUserRolesbyAccountId, deleteUserRole } from '../../services/UserRoleService';
import { GetAllAccountsList } from '../../services/AccountService';
import { Role, User } from '../../models/UserRole';
import { v4 as uuidv4 } from 'uuid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import { Typography } from 'antd';
import styles from "../settings/UserRoles.module.scss";
import loader from "../../assets/loader.gif";
import FilterPopup from '../settings/Filterpopup';
import { useSnackbar } from '../../SnackbarContext';
import { getOrganizationById } from '../../services/OrganizationService';

const TeamSettings: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
    const [roles, setRoles] = useState<Role[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<{ RoleName: string, RoleId: string }[]>([]);
    const [organizationUsers, setOrganizationUsers] = useState<string[]>([]);
    const [selectedEmail, setSelectedEmail] = useState<string>('');
    const [userRoles, setUserRoles] = useState<any[]>([]);
    const [accounts, setAccounts] = useState<{ id: string, name: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const storedOrganizationString = localStorage.getItem("organization");
    const storedOrganization = storedOrganizationString ? JSON.parse(storedOrganizationString) : null;
    const OrganizationId = storedOrganization.OrganizationId;
    const timeZone = storedOrganization ? storedOrganization.TimeZone : "UTC";
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 15,
    });
    const { openSnackbar } = useSnackbar();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isDialogReadOnly, setIsDialogReadOnly] = useState(false);
    const [dialogMode, setDialogMode] = useState<'add' | 'edit'>('add');
    const [currentRow, setCurrentRow] = useState<any>(null);
    const [selectedAccount, setSelectedAccount] = useState<{ id: string, name: string } | null>(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [userIdroletodelete, setuserIdroletodelete] = useState<string | null>(null);
    const [roleToDelete, setRoleToDelete] = useState<string | null>(null);
    const [responseOfUserRoleList, setresponseOfUserRoleList] = useState<any[]>([]);
    const [emailToUserIdMap, setEmailToUserIdMap] = useState<Record<string, string>>({});
    const [skip, setskip] = useState(0);
    const [top, settop] = useState(0);
    const [totalcount, setTotalcount] = useState(0);
    const handleAdd = (row: any) => {
        setDialogMode('add');
        setCurrentRow(row);
        setSelectedEmail(row.EmailId);
        setIsDialogReadOnly(false);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setCurrentRow(null);
    };
    const handleAddRoleClick = (row: any) => {
        setDialogMode('add');
        setCurrentRow(row);
        setSelectedEmail(row.EmailId);
        if (selectedAccount) {
            setIsDialogReadOnly(true);
        } else {
            setIsDialogReadOnly(false);
        }
        setDialogOpen(true);
    };
    const userId = "20062024-120428984-e964b405-7e3a-499a-8ed0-a618a69df689";
    useEffect(() => {
        const unsubscribe = subscribe(setSidebarOpen);
        return () => unsubscribe();
    }, []);
    const [roleNameToIdMap, setRoleNameToIdMap] = useState<Record<string, string>>({});
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                setLoading(true);
                const rolesData = await getUserRoles();
                setRoles(rolesData);
                const roleMap = rolesData.reduce((map: any, role: any) => {
                    map[role.RoleName] = role.RoleId;
                    return map;
                }, {} as Record<string, string>);
                setRoleNameToIdMap(roleMap);
            } catch (error) {
                console.error("Failed to fetch roles", error);
            }
        };

        fetchRoles();
        setLoading(false);
    }, [OrganizationId, userId]);
    useEffect(() => {
        const fetchOrganizationUsers = async () => {
            try {
                setLoading(true);
                const payload = {
                    organizationId: OrganizationId ? OrganizationId : null,
                    skip: 0,
                    top: 25,
                    orderByFields: "",
                    filters: []
                };
                const users: User[] = await GetOrganizationUsers(payload);
                setOrganizationUsers(users.map(user => user.EmailId));
                const map = users.reduce((acc: any, user) => {
                    acc[user.EmailId] = user.UserId;
                    return acc;
                }, {} as Record<string, string>);
                setEmailToUserIdMap(map);
            } catch (error) {

                console.error("Failed to fetch organization users", error);
            }
        };
        fetchOrganizationUsers();
        setLoading(false);
    }, [OrganizationId]);

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                setLoading(true);
                const skipcount = paginationModel.pageSize || 10;
                const limitcount = paginationModel.pageSize;
                const skips = paginationModel.page * skipcount;
                const top = limitcount;
                setskip(skips);
                settop(top);
                const filters: any = [];
                const orderByFields = "";
                const organizationId = "";
                const accountsData = await GetAllAccountsList(skip, top, orderByFields, filters, organizationId);
                setAccounts(accountsData.map(account => ({ id: account.AccountId, name: account.AccountName })));
            } catch (error) {
                console.error("Failed to fetch accounts", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAccounts();
    }, []);

    const handleDeleteRole = async () => {
        if (roleToDelete) {
            try {
                await deleteUserRole(roleToDelete, userIdroletodelete, openSnackbar);
                fetchUserRoles();
            } catch (error) {
                console.error('Failed to delete role', error);
            }
        }
        setConfirmDialogOpen(false);
        setRoleToDelete(null);
    };

    const ConfirmationDialog: React.FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this role?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleDeleteRole} variant="contained" color="primary">
                    Yes, Delete
                </Button>
            </DialogActions>
        </Dialog>
    );


    const fetchUserRoles = async () => {
        if (selectedAccount) {
            // Fetching by Account
            try {
                setLoading(true);
                const skipcount = paginationModel.pageSize || 15;
                const limitcount = paginationModel.pageSize || 15;
                const skips = paginationModel.page * skipcount;
                const top = limitcount;
                setskip(skips);
                settop(top);
                const filters: any = [];
                const orderByFields = "";
                const data = await GetAllUserRolesbyAccountId(selectedAccount.id, top, skips, orderByFields, filters, setTotalcount);
                setresponseOfUserRoleList(data);

                const roleIdToNameMap = data.reduce((map: Record<string, string>, role: any) => {
                    map[role.UserRoleId] = role.RoleName;
                    return map;
                }, {});
                const groupedData = data.reduce((acc: any, role: any) => {
                    if (!acc[role.EmailId]) {
                        acc[role.EmailId] = {
                            UserName: role.UserName,
                            EmailId: role.EmailId,
                            Roles: new Set(),
                            UserRoleId: new Set()
                        };
                    }
                    acc[role.EmailId].Roles.add(role.RoleName);
                    acc[role.EmailId].UserRoleId.add(role.UserRoleId);
                    return acc;
                }, {});

                const aggregatedData = Object.values(groupedData).map((item: any) => ({
                    ...item,
                    Roles: Array.from(item.Roles),
                    UserRoleId: Array.from(item.UserRoleId)
                }));
                const rolesWithUniqueIds = aggregatedData.map((role: any) => ({ ...role, id: uuidv4() }));
                setUserRoles(rolesWithUniqueIds);
            } catch (error) {
                console.error('Failed to fetch user roles', error);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                setLoading(true);
                const skipcount = paginationModel.pageSize || 15;
                const limitcount = paginationModel.pageSize || 15;
                const skips = paginationModel.page * skipcount + limitcount;
                const top = 0;
                setskip(skips);
                settop(top);
                const filters: any = [];
                const orderByFields = "";
                const data = await GetAllUserRolesbyOrganizationId(OrganizationId, top, skips, orderByFields, filters, setTotalcount);
                setresponseOfUserRoleList(data);

                const roleIdToNameMap = data.reduce((map: Record<string, string>, role: any) => {
                    map[role.UserRoleId] = role.RoleName;
                    return map;
                }, {});
                const groupedData = data.reduce((acc: any, role: any) => {
                    if (!acc[role.EmailId]) {
                        acc[role.EmailId] = {
                            UserName: role.UserName,
                            EmailId: role.EmailId,
                            Roles: new Set(),
                            UserRoleId: new Set()
                        };
                    }
                    acc[role.EmailId].Roles.add(role.RoleName);
                    acc[role.EmailId].UserRoleId.add(role.UserRoleId);
                    return acc;
                }, {});

                const aggregatedData = Object.values(groupedData).map((item: any) => ({
                    ...item,
                    Roles: Array.from(item.Roles),
                    UserRoleId: Array.from(item.UserRoleId)
                }));
                const rolesWithUniqueIds = aggregatedData.map((role: any) => ({ ...role, id: uuidv4() }));
                setUserRoles(rolesWithUniqueIds);
            } catch (error) {
                console.error('Failed to fetch user roles', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchUserRoles();
    }, [paginationModel, selectedAccount]);


    const ActionDialog: React.FC<{ open: boolean, onClose: () => void, isReadOnly: boolean }> = ({ open, onClose, isReadOnly }) => {
        const [localSelectedEmail, setLocalSelectedEmail] = useState<string[]>([]);
        const [localSelectedRoles, setLocalSelectedRoles] = useState<{ RoleName: string, RoleId: string }[]>([]);
        const [localSelectedAccount, setLocalSelectedAccount] = useState<{ id: string, name: string } | null>(selectedAccount);

        useEffect(() => {
            if (selectedEmail) {
                setLocalSelectedEmail([selectedEmail].filter(Boolean));
            }
            setLocalSelectedAccount(selectedAccount);
        }, [selectedAccount, selectedEmail]);

        const handleRoleChange = (event: SelectChangeEvent<string[]>) => {
            const selectedRoleNames = event.target.value as string[];
            const selectedRoleObjects = selectedRoleNames.map(roleName => {
                const role = roles.find(r => r.RoleName === roleName);
                return { RoleName: roleName, RoleId: role?.RoleId || '' };
            });
            setLocalSelectedRoles(selectedRoleObjects);
        };

        const handleUserChange = (event: SelectChangeEvent<string[]>) => {
            setLocalSelectedEmail((event.target.value as string[]).filter(Boolean));
        };

        const handleSave = async () => {
            try {

                const rolesData = [];
                for (const email of localSelectedEmail) {
                    if (!email) continue;
                    const userId = emailToUserIdMap[email];
                    const existingRolesResponse = responseOfUserRoleList;
                    const existingRoles = existingRolesResponse.filter((role: any) => role.EmailId === email);
                    const existingRoleNames = new Set(existingRoles.map((role: any) => role.RoleName));

                    const duplicateRoles = localSelectedRoles.filter(role => existingRoleNames.has(role.RoleName));

                    const userRolesData = localSelectedRoles.map(role => ({
                        // Id: generateCustomUserId(),
                        RoleId: role.RoleId,
                        RoleName: role.RoleName,
                        organizationId: OrganizationId,
                        userId: userId,
                        AccountId: localSelectedAccount ? localSelectedAccount.id : null,
                        CreatedDateTime: new Date().toISOString()
                    }));
                    rolesData.push(...userRolesData);
                }
                await saveUserRoles(rolesData, openSnackbar);

                fetchUserRoles();
            } catch (error) {

                console.error('Failed to save data', error);
            }
            onClose();
        };


        const isSaveButtonDisabled = () => {
            return localSelectedEmail.length === 0 || localSelectedRoles.length === 0 || !localSelectedAccount;
        };

        return (
            <Dialog slotProps={{
                backdrop:{invisible:true}
            }} open={open} onClose={onClose} fullWidth>
                <DialogTitle>Manage User Roles</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ marginTop: "2px" }}>
                        <Grid item xs={12} sx={{ marginTop: "2px" }}>
                            <FormControl fullWidth required >
                                <InputLabel id="select-user-label">Select User</InputLabel>
                                <Select
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                height: "344px",
                                                marginTop: "5px",
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                    labelId="select-user-label"
                                    multiple
                                    value={localSelectedEmail}
                                    onChange={handleUserChange}
                                    label="Select User"
                                    renderValue={(selected) => (selected as string[]).filter(Boolean).join(', ')}
                                    disabled={isReadOnly}
                                >
                                    {organizationUsers.map((user) => (
                                        <MenuItem key={user} value={user}>
                                            <Checkbox checked={localSelectedEmail.includes(user)} />
                                            <ListItemText sx={{
                                                "&. MuiPopover-paper-MuiMenu-paper": {
                                                    height: "344px",
                                                    marginTop: "205px"
                                                }
                                            }} primary={user} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="select-account-label">Select Account</InputLabel>
                                <Select
                                    required
                                    labelId="select-account-label"
                                    value={localSelectedAccount ? localSelectedAccount.id : ''}
                                    onChange={(e) => {
                                        const selectedId = e.target.value;
                                        const selectedAccount = accounts.find(account => account.id === selectedId);
                                        setLocalSelectedAccount(selectedAccount || null);
                                    }}
                                    label="Select Account"
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                top: "353px",
                                                height: "264px",
                                                marginTop: "5px",
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                    disabled={isReadOnly}
                                >
                                    {accounts.map((account) => (
                                        <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="roles-label">Roles</InputLabel>
                                <Select
                                    labelId="roles-label"
                                    id="roles-select"
                                    multiple
                                    value={localSelectedRoles.map(role => role.RoleName)}
                                    onChange={handleRoleChange}
                                    input={<OutlinedInput label="Roles" />}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                >
                                    {roles.map((role) => (
                                        <MenuItem key={role.RoleName} value={role.RoleName}>
                                            <Checkbox checked={localSelectedRoles.some(selectedRole => selectedRole.RoleName === role.RoleName)} />
                                            <ListItemText primary={role.RoleName} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button disabled={isSaveButtonDisabled()} onClick={handleSave} variant='contained' color='primary'>Save</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const columns: GridColDef[] = [
        { field: 'UserName', headerClassName: "header", headerName: 'Full Name', width: 150, disableColumnMenu: true, },
        { field: 'EmailId', headerClassName: "header", headerName: 'User', width: 150, disableColumnMenu: true, },
        {
            field: 'Roles',
            headerName: 'Role Name',
            headerClassName: "header",
            width: 480,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {params.value.map((roleName: string) => (
                        <Chip
                            key={roleName}
                            label={roleName}
                            onDelete={() => {
                                const emailId = params.row.EmailId;
                                const roleEntry = responseOfUserRoleList.find((r: any) => r.RoleName === roleName && r.EmailId === emailId);
                                if (roleEntry) {
                                    const userRoleId = roleEntry.UserRoleId;
                                    const userIdtodelete = roleEntry.UserId;
                                    if (userRoleId) {
                                        setuserIdroletodelete(userIdtodelete);
                                        setRoleToDelete(userRoleId);
                                        setConfirmDialogOpen(true);
                                    } else {
                                        console.error(`UserRoleId for ${roleName} not found`);
                                    }
                                } else {
                                    console.error(`Role ${roleName} not found`);
                                }
                            }}
                            deleteIcon={
                                <Tooltip title="Delete Role" arrow>
                                    <CancelIcon style={{ fontSize: "17px" }} sx={{ cursor: 'pointer' }} />
                                </Tooltip>
                            }
                            variant="outlined"
                            sx={{
                                height: 24,
                                fontSize: '0.875rem',
                                backgroundColor: "blue",
                                color: "white",
                                "& .MuiChip-deleteIcon": {
                                    color: "floralwhite"
                                }
                            }}
                        />
                    ))}
                </Box>
            ),
        },

        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: "header",
            width:sidebarOpen?100:180,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', gap: 1, marginTop: "11px" }}>
                    <Tooltip arrow title="Add User Role">
                        <AddBoxIcon
                            className={styles.Addiconingrid}
                            onClick={() => handleAddRoleClick(params.row)}
                        />
                    </Tooltip>
                </Box>
            ),
           
        },
    ];

    const handleSearch = (value: string[]) => {
        setSearchText(value.join(' '));
    };

    const filteredRows = userRoles.filter((row: any) => {
        const emailId = row.EmailId || '';
        const roleName = row.RoleName || '';
        const userName = row.UserName || '';

        return emailId.toLowerCase().includes(searchText.toLowerCase()) ||
            roleName.toLowerCase().includes(searchText.toLowerCase()) ||
            userName.toLowerCase().includes(searchText.toLowerCase());
    });
    const uniqueUserNames = Array.from(new Set(userRoles.map((row: any) => row.UserName)));
    const uniqueEmails = Array.from(new Set(responseOfUserRoleList.map(role => role.EmailId)));
    const uniqueRoles = Array.from(new Set(responseOfUserRoleList.flatMap(role => role.Roles)));
    const [open, setOpen] = useState(false);

    const selectRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        if (sidebarOpen) {
            setOpen(false);
        }
    }, [sidebarOpen]);

    useEffect(() => {

        const handleClickOutside = (event: any) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e: any) => {
        const selectedId = e.target.value;
        const selectedAccount = accounts.find(account => account.id === selectedId);
        setSelectedAccount(selectedAccount || null);
        setOpen(false);
    };
    const [orderByFields, setOrderByFields] = useState("");
    const [sortModel, setSortModel] = useState([]);
    const [filters, setFilters] = useState([]);
    const handleSortModelChange = (model: any) => {
        if (model.length && model[0].field === "EmailId") {
            setSortModel(model);
            const orderByField = model
                .map((item: any) =>
                    item.sort === "" || item.sort === "asc"
                        ? `EmailId` : (item.sort === "desc" && item.field === "EmailId") ? `EmailId desc`
                            : item.sort === "desc" && item.field !== "EmailId"
                                ? `${item.field} desc`
                                : item.field
                )
                .join(", ");
            setOrderByFields(orderByField);
            if (selectedAccount) {

                GetAllUserRolesbyAccountId(selectedAccount?.id, top, skip, orderByField, filters, setTotalcount);
            }
            else {
                GetAllUserRolesbyOrganizationId(OrganizationId, top, skip, orderByField, filters, setTotalcount);
            }
        } else if (model.length === 0) {
            setSortModel(model);
            const orderByField = model
                .map((item: any) =>
                    item.sort === "" || item.sort === "asc"
                        ? `EmailId` : (item.sort === "desc" && item.field === "EmailId") ? `EmailId desc`
                            : item.sort === "desc" && item.field !== "EmailId"
                                ? `${item.field} desc`
                                : item.field
                )
                .join(", ");
            setOrderByFields(orderByField);
            if (selectedAccount) {
                GetAllUserRolesbyAccountId(selectedAccount?.id, top, skip, orderByField, filters, setTotalcount);
            }
            else {
                GetAllUserRolesbyOrganizationId(OrganizationId, top, skip, orderByField, filters, setTotalcount);
            }
        }
    };

    const columnNames = columns.map(col => col.headerName);
    const actionColumns = ['Actions', 'Full Name'];
    const filteredColumnNames = columnNames
        .filter(
            (name): name is string => name !== undefined && !actionColumns.includes(name)
        )
        .map(name => name === 'Role Name' ? name.replace(/\s+/g, '') : name);

    const handleApplyFilters = async (filters: any) => {
        if (selectedAccount) {
            setFilters(filters);
            let skips: number = 0;
            let top: number = 0;
            const skipcount = paginationModel.pageSize || 15;
            const limitcount = paginationModel.page * skipcount;
            skips = paginationModel.pageSize;
            top = limitcount;

            const formattedFilters = filters.map((filter: any) => ({
                FieldName: filter.column,
                ElementType: "string",
                Condition: filter.operator,
                Value: filter.value,
                IsCustomField: false
            }));
            setskip(skips);
            settop(top);
            const orderByFields = "";
            try {
                const data = await GetAllUserRolesbyAccountId(selectedAccount?.id, skip, top, orderByFields, formattedFilters, setTotalcount);
                setresponseOfUserRoleList(data);

                const roleIdToNameMap = data.reduce((map: Record<string, string>, role: any) => {
                    map[role.UserRoleId] = role.RoleName;
                    return map;
                }, {});

                const groupedData = data.reduce((acc: any, role: any) => {
                    if (!acc[role.EmailId]) {
                        acc[role.EmailId] = {
                            UserName: role.UserName,
                            EmailId: role.EmailId,
                            Roles: new Set(),
                            UserRoleId: new Set()
                        };
                    }
                    acc[role.EmailId].Roles.add(role.RoleName);
                    acc[role.EmailId].UserRoleId.add(role.UserRoleId);
                    return acc;
                }, {});

                const aggregatedData = Object.values(groupedData).map((item: any) => ({
                    ...item,
                    Roles: Array.from(item.Roles),
                    UserRoleId: Array.from(item.UserRoleId)
                }));
                const rolesWithUniqueIds = aggregatedData.map((role: any) => ({ ...role, id: uuidv4() }));
                setUserRoles(rolesWithUniqueIds);
            } catch (error) {
                console.error("Failed to fetch user roles:", error);
            }
        } else {
            setFilters(filters);
            let skips: number = 0;
            let top: number = 0;
            const skipcount = paginationModel.pageSize || 15;
            const limitcount = paginationModel.pageSize;
            skips = paginationModel.page * skipcount;
            top = limitcount;

            const formattedFilters = filters.map((filter: any) => ({
                FieldName: filter.column,
                ElementType: "string",
                Condition: filter.operator,
                Value: filter.value,
                IsCustomField: false
            }));
            setskip(skips);
            settop(top);
            const orderByFields = "";
            try {
                const data = await GetAllUserRolesbyOrganizationId(OrganizationId, skip, top, orderByFields, formattedFilters, setTotalcount);
                setresponseOfUserRoleList(data);

                const roleIdToNameMap = data.reduce((map: Record<string, string>, role: any) => {
                    map[role.UserRoleId] = role.RoleName;
                    return map;
                }, {});

                const groupedData = data.reduce((acc: any, role: any) => {
                    if (!acc[role.EmailId]) {
                        acc[role.EmailId] = {
                            UserName: role.UserName,
                            EmailId: role.EmailId,
                            Roles: new Set(),
                            UserRoleId: new Set()
                        };
                    }
                    acc[role.EmailId].Roles.add(role.RoleName);
                    acc[role.EmailId].UserRoleId.add(role.UserRoleId);
                    return acc;
                }, {});

                const aggregatedData = Object.values(groupedData).map((item: any) => ({
                    ...item,
                    Roles: Array.from(item.Roles),
                    UserRoleId: Array.from(item.UserRoleId)
                }));
                const rolesWithUniqueIds = aggregatedData.map((role: any) => ({ ...role, id: uuidv4() }));
                setUserRoles(rolesWithUniqueIds);
            } catch (error) {
                console.error("Failed to fetch user roles:", error);
            }
        }
    };



    return (
        <Container maxWidth="xl" sx={{width:sidebarOpen?"98%":"103%", marginLeft: sidebarOpen ? "8px" : "-121px", marginRight: "6px", backgroundColor : 'white', borderRadius: '6px' }} >
            <div className={styles.smoothtransition} style={{ marginLeft: sidebarOpen ? "8px" : "0px",marginTop:"20px" }}>
                <div className={styles.teamSettings}>
                    <div>
                        <Typography className={styles.UserRoleTitle}> Roles</Typography>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Box sx={{ position: 'relative', height: '100%' }}>
                                <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}>
                                    <FormControl fullWidth className={sidebarOpen ? styles.selectaccountGlobal : styles.selectaccountGloballeft} sx={{
                                        maxWidth: 200, right: "51px", "& .MuiOutlinedInput-input": {
                                            padding: "0px",
                                            marginTop: "5px",
                                            marginLeft: "31px",
                                            width:"278px"
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius: "5px",
                                            height: "35px",
                                        },
                                        "& .MuiInputLabel-outlined": {
                                            top: "-9px",
                                        },
                                        "& .MuiAutocomplete-inputRoot": {
                                            height: "35px",
                                        },

                                    }}
                                        ref={selectRef}>
                                        <InputLabel id="select-account-label">Select Account</InputLabel>
                                        <Select
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        height: "344px",
                                                        marginTop: "5px",
                                                        overflowY: 'auto',
                                                        position: "absolute"
                                                    },
                                                },
                                            }}
                                            labelId="select-account-label"
                                            value={selectedAccount ? selectedAccount.id : ''}
                                            open={open}
                                            onChange={handleChange}
                                            onOpen={handleOpen}
                                            onClose={handleClose}
                                            label="Select Account"
                                        >
                                            {accounts.map((account) => (
                                                <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Button
                                className={sidebarOpen ? styles.addUserroleglobal : styles.addUserrolegloballeft}
                                variant='contained'
                                onClick={handleAdd}
                                sx={{height:"27px"}}
                            >
                                <AddBoxIcon />
                                add role
                            </Button>
                            <Box sx={{ position: 'relative', height: '100%' }}>
                                <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1000, left: sidebarOpen ? "595px" : "630px" }}>
                                    <FilterPopup columns={filteredColumnNames} onApplyFilters={handleApplyFilters} />
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                    <ActionDialog open={dialogOpen} onClose={handleCloseDialog} isReadOnly={isDialogReadOnly} />
                    <ConfirmationDialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} />
                    {
                        loading ? (
                            <div
                                className={styles.Loaderstyles}
                            >
                                <img
                                    src={loader}
                                    alt="Spinner"
                                    className={styles.LoaderSpinnerStyles}
                                />
                                please wait ...
                                <br />
                            </div>
                        ) : (
                            <Box sx={{
                                height: 395, width: sidebarOpen ? "98%" : '103%', marginTop: 2, marginLeft: sidebarOpen ? "1px" : '-10px', '& .header': {
                                    backgroundColor: '#a3c2c2',
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    fontWeight: "bold"
                                },
                                "& .MuiDataGrid-columnHeaderMenuIcon": {
                                    visibility: 'visible !important'
                                }
                            }}>
                                <DataGrid
                                    sx={{
                                        borderColor: "black",
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundImage: "linear-gradient(to right, rgb(30, 138, 201), rgba(62, 200, 241, 0.7))",
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontWeight: "bold",
                                            color: "white",
                                        },
                                        "& .MuiDataGrid-columnHeader": {
                                            backgroundImage: "linear-gradient(to right, rgb(30, 138, 201), rgba(62, 200, 241, 0.7))",
                                            color: "white",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderRight: "1px solid #ddd",
                                        },
                                        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
                                            borderRight: "1px solid #ddd",
                                        },
                                        "& .MuiDataGrid-row": {
                                            "& .MuiDataGrid-cell": {
                                                borderBottom: "none",
                                            },
                                        },
                                    }}
                                    rows={filteredRows}
                                    columns={columns}
                                    getRowId={(row) => row.id}
                                    pagination
                                    paginationMode="server"
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={(model) => {
                                        setPaginationModel(model);
                                    }}
                                    pageSizeOptions={[15, 25, 50, 100]}
                                    localeText={{
                                        MuiTablePagination: {
                                            labelRowsPerPage: "Records Per Page",
                                        },
                                    }}
                                    disableRowSelectionOnClick={true}
                                    loading={loading}
                                    rowHeight={58}
                                    rowCount={totalcount}
                                    sortModel={sortModel}
                                    onSortModelChange={handleSortModelChange}
                                />
                            </Box>
                        )
                    }
                </div>
            </div>
        </Container>
    );
};
export default TeamSettings;
