import { User } from "../models/User";
import { adminApiService, userApiService, adminUrl, userUrl } from "./APIService";
import { useSnackbar } from "../SnackbarContext";

// --------------------------------User APIs --------------------

export const getAllUsers = async (): Promise<User[] | null> => {
	try {
		const response = await adminApiService.get<User[]>("/User/GetAllUsersForAllOrganizations");
		return response.data;
	} catch (error) {
		//console.error("Error fetching all users:", error);
		throw error;
	}
};
export const GetUserDetails = async (): Promise<User | null> => {
	try {
		const response = await adminApiService.get<User>("/User/GetUserDetails");
		return response.data;
	} catch (error) {
		throw error;
	}
};
export const fetchUserDataFromApi = async (setModels: any, setLoading: any) => {
	try {
		setLoading(true);
		//console.log(adminApiService);
		const response = await adminApiService.get("/User/GetAllUsersForAllOrganizations");
		const apiData = response.data;

		if (Array.isArray(apiData)) {
			setModels(apiData);
		} else {
			//console.error("Invalid data format from API:", apiData);
		}
	} catch (error) {
		console.error("Error fetching models:", error);
	} finally {
		// setLoading(false);
	}
};

export const fetchUsersList = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	orderByField: any,
	filters: any,

) => {
	try {
		setLoading(true);
		const requestBody = {
			skip,
			top,
			orderByFields: orderByField, // Assuming an empty string for now, adjust as necessary
			filters: filters, // Assuming an empty array for now, adjust as necessary
			organizationId: OrganizationId,
		};
		const response = await adminApiService.post(`/User/GetOrganizationUsers`, requestBody);
		const apiData = response.data.results;
		setTotalcount(response.data._count);

		if (Array.isArray(apiData)) {
			// Extract usernames
			const usernames = apiData.map(user => user.UserName);
			// Optionally, set the entire user data if needed
			setModels(apiData);
			
		  } else {
		  }
	} catch (error) {
	} finally {
		// setLoading(false);
	}
};

// export const SubmitCreateUser = async (
// 	setLoading: any,
// 	setShowPopup: any,
// 	setModels: any,
// 	setShowEditPopup: any,
// 	inputs: any,
// 	OrganizationId: any,
// 	skip: any,
// 	top: any,
// 	setTotalcount: any,
// 	openSnackbar: any
// ) => {
// 	const response = await fetch(`${adminUrl}/User/CreateUser`, {
// 		method: "POST",
// 		headers: {
// 			"Content-Type": "application/json",
// 		},
// 		body: JSON.stringify(inputs),
// 	})
// 		.then((response) => {
// 			if (!response.ok) {
// 				throw new Error("Network response was not ok");
// 			} else {
// 				const data = await response.json();
// 				if (response.ok && data.Success) {
// 					console.log(data);
// 					//setMessage(`Success: ${data.message}`);
// 					openSnackbar(data.SuccessMessage, "success");
// 				} else {
// 					//setMessage(`Error: ${data.message}`);
// 					openSnackbar(data.ErrorMessage, "error");
// 				}
// 			}
// 			// Assuming 204 No Content is a successful response

// 			console.log("User created successfully");
// 			setShowEditPopup(false);
// 			setShowPopup(false);
// 			//fetchUserDataFromApi(setModels, setLoading);

// 			fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount);
// 		})
// 		.catch((error) => {
// 			console.error("Error creating user:", error);
// 		});
// };

export const SubmitCreateUser = async (
	setLoading: any,
	setShowPopup: any,
	setModels: any,
	setShowEditPopup: any,
	inputs: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	try {
		setLoading(true);
		const response = await fetch(`${adminUrl}/User/CreateUser`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(inputs),
		});

		if (response.ok) {
			const data = await response.json();
			if (data.Success) {
				openSnackbar(data.SuccessMessage, "success");
			} else {
				openSnackbar(data.ErrorMessage, "error");
			}
			setShowEditPopup(false);
			setShowPopup(false);
			fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} else {
			console.error("Failed to create user");
		}
	} catch (error) {
		console.error("Error creating user:", error);
	} finally {
		setLoading(false);
	}
};

export const SubmitCreatenewUser = async (
	setLoading: any,
	setShowPopup: any,
	setModels: any,
	setShowEditPopup: any,
	inputs: any,
	setSnackbarOpen: any,
	setSnackbarMessage: any,
	setSnackbarSeverity: any,

	onSuccess: () => void
) => {
	setLoading(true);
	try {
		const response = await fetch(`${adminUrl}/User/CreateUser`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(inputs),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		// Assuming 204 No Content is a successful response
		console.log("Admin created successfully");
		setShowEditPopup(false);
		setShowPopup(false);
		fetchUserDataFromApi(setModels, setLoading);

		// Show success snackbar
		setSnackbarMessage("Admin created successfully");
		setSnackbarSeverity("success");
		setSnackbarOpen(true);
		setTimeout(onSuccess, 5000);

		// Call the success callback
		onSuccess();
	} catch (error) {
		console.error("Error creating user:", error);

		// Show error snackbar
		setSnackbarSeverity("error");
		setSnackbarMessage("Failed to create Admin");
		setSnackbarOpen(true);
	}
};

export const SubmitUserDetail = async (setLoading: any, setModels: any, setShowEditPopup: any, userDetails: any) => {
	setLoading(true);
	const response = await fetch(`${adminUrl}/User/UpdateUser`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(userDetails),
	});

	if (response.ok) {
		// Handle successful update (e.g., show a success message, close the popup)
		setShowEditPopup(false);
		fetchUserDataFromApi(setModels, setLoading);
	} else {
		// Handle error
		console.error("Failed to update user details");
	}
};

export const SubmitUserDetails = async (
	setLoading: any,
	setModels: any,
	setShowEditPopup: any,
	userDetails: any,
	skip: any,
	top: any,
	OrganizationId: any,
	setTotalcount: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	try {
		setLoading(true);
		const response = await fetch(`${adminUrl}/User/UpdateUser`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(userDetails),
		});

		if (response.ok) {
			const data = await response.json();
			if (response.ok && data.Success) {
				console.log(data);
				//setMessage(`Success: ${data.message}`);
				openSnackbar(data.SuccessMessage, "success");
			} else {
				//setMessage(`Error: ${data.message}`);
				openSnackbar(data.ErrorMessage, "error");
			}
			// Handle successful update (e.g., show a success message, close the popup)
			setShowEditPopup(false);
			//fetchUserDataFromApi(setModels, setLoading);

			fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} else {
			// Handle error

			//console.error("Failed to update user details");
		}
	} catch (error: any) {
		//console.error("Failed to update user details");
	}
};

export const DeleteUserDetails = async (
	emailiddelete: any,
	setShowDeletePopup: any,
	fetchUserDataFromApi: any,
	setModels: any,
	setLoading: any
) => {
	setLoading(true);
	fetch(`${adminUrl}/User/DeleteUser?emailId=${emailiddelete}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(emailiddelete),
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			// Assuming 204 No Content is a successful response
			//console.log("User Deleted successfully");
			setShowDeletePopup(false);
			fetchUserDataFromApi(setModels, setLoading);
		})
		.catch((error) => {
			console.error("Error Deleting user:", error);
		});
};

export const Submitdisableuser = async (setLoading: any, setModels: any, userid: any) => {
	const response = await fetch(`${adminUrl}/User/CanUserBeDeactivated?userId=${userid}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(userid),
	});

	if (response.ok) {
		// Handle successful update (e.g., show a success message, close the popup)
		//console.log(" User Successfully Disabled");
		fetchUserDataFromApi(setModels, setLoading);
	} else {
		// Handle error
		//console.error("Failed to Disable User");
	}
};

export const changeUserPassword = async (
	setLoading: any,
	setShowResetPasswordPopup: any,
	userId: any,
	newPassword: any,
	openSnackbar: any
) => {
	setLoading(true); // Set loading state to true

	const response = await fetch(`${userUrl}/User/ChangeUserPassword?userId=${userId}&newPassword=${newPassword}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ userId, newPassword }), // Send userId and newPassword in the body
	});

	if (response.ok) {
		const data = await response.json();
		if (response.ok && data.Success) {
			//console.log(data);
			//setMessage(`Success: ${data.message}`);
			openSnackbar(data.SuccessMessage, "success");
		} else {
			//setMessage(`Error: ${data.message}`);
			openSnackbar(data.ErrorMessage, "error");
		}
		// Handle successful password change (e.g., show a success message)
		setShowResetPasswordPopup(false);
		//console.log("Password successfully changed");
	} else {
		// Handle error
		//console.error("Failed to change password");
	}

	//setLoading(false); // Set loading state to false
};

export const confirmEmail = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	//const { openSnackbar } = useSnackbar();
	setLoading(true); // Set loading state to true

	const response = await fetch(`${userUrl}/User/ConfirmEmail?userId=${userId}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	});

	if (response.ok) {
		// Handle successful email confirmation
		const data = await response.json();
		if (response.ok && data.Success) {
			//console.log(data);
			//setMessage(`Success: ${data.message}`);
			openSnackbar(data.SuccessMessage, "success");
		} else {
			//setMessage(`Error: ${data.message}`);
			openSnackbar(data.ErrorMessage, "error");
		}
		//console.log("Email successfully confirmed");
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
	} else {
		// Handle error
		console.error("Failed to confirm email");
	}

	//setLoading(false); // Set loading state to false
};

export const BlockUser = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	setLoading(true); // Set loading state to true

	try {
		const response = await fetch(`${userUrl}/User/BlockUser?userId=${userId}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (response.ok) {
			const data = await response.json();
			if (response.ok && data.Success) {
				//console.log(data);
				//setMessage(`Success: ${data.message}`);
				openSnackbar(data.SuccessMessage, "success");
			} else {
				//setMessage(`Error: ${data.message}`);
				openSnackbar(data.ErrorMessage, "error");
			}
			// Handle successful user block
			//console.log("User successfully blocked");
			fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} else {
			// Handle error
			console.error("Failed to block user");
		}
	} catch (error) {
		// Handle any network or unexpected errors
		console.error("An error occurred while blocking the user:", error);
	}

	//setLoading(false); // Set loading state to false
};

export const UnblockUser = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	setLoading(true); // Set loading state to true

	try {
		const response = await fetch(`${userUrl}/User/UnblockUser?userId=${userId}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (response.ok) {
			const data = await response.json();
			if (response.ok && data.Success) {
				//console.log(data);
				//setMessage(`Success: ${data.message}`);
				openSnackbar(data.SuccessMessage, "success");
			} else {
				//setMessage(`Error: ${data.message}`);
				openSnackbar(data.ErrorMessage, "error");
			}
			// Handle successful user unblock
			//console.log("User successfully unblocked");
			// Refresh the list of users
			fetchUsersList(setModels,setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} else {
			// Handle error
			//console.error("Failed to unblock user");
		}
	} catch (error) {
		// Handle any network or unexpected errors
		console.error("An error occurred while unblocking the user:", error);
	}

	//setLoading(false); // Set loading state to false
};

export const deactivateUser = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	setLoading(true); // Set loading state to true

	try {
		const response = await fetch(`${userUrl}/User/DeActivateUser?userId=${userId}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (response.ok) {
			const data = await response.json();
			if (response.ok && data.Success) {
				//console.log(data);
				//setMessage(`Success: ${data.message}`);
				openSnackbar(data.SuccessMessage, "success");
			} else {
				//setMessage(`Error: ${data.message}`);
				openSnackbar(data.ErrorMessage, "error");
			}
			// Handle successful user deactivation
			//console.log("User successfully deactivated");
			// Refresh the list of users
			fetchUsersList(setModels,setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} else {
			// Handle error
			console.error("Failed to deactivate user");
		}
	} catch (error) {
		// Handle any network or unexpected errors
		console.error("An error occurred while deactivating the user:", error);
	}

	//setLoading(false); // Set loading state to false
};

export const activateUser = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	setLoading(true); // Set loading state to true

	try {
		const response = await fetch(`${userUrl}/User/ActivateUser?userId=${userId}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (response.ok) {
			const data = await response.json();
			if (response.ok && data.Success) {
				//console.log(data);
				//setMessage(`Success: ${data.message}`);
				openSnackbar(data.SuccessMessage, "success");
			} else {
				//setMessage(`Error: ${data.message}`);
				openSnackbar(data.ErrorMessage, "error");
			}
			// Handle successful user activation
			//console.log("User successfully activated");
			// Refresh the list of users
			fetchUsersList(setModels,setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} else {
			// Handle error
			console.error("Failed to activate user");
		}
	} catch (error) {
		// Handle any network or unexpected errors
		console.error("An error occurred while activating the user:", error);
	}

	//setLoading(false); // Set loading state to false
};
  
  
  
