import React, { useState, useEffect, ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { getAllOrganizations } from "../../services/OrganizationService";
import { SubmitCreateAccount } from "../../services/AccountService";
import { TextField, Select, MenuItem, Button, IconButton, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "../../SnackbarContext";
type InputFields = {
	AccountName: string;
};
type ErrorFields = Partial<InputFields>;

const CreateAccount = (props: any) => {
	const { setLoading, setModels, showPopup, setShowPopup, orderByField, filters } = props;
	// const [showPopup, setShowPopup] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const storedOrganizationString = localStorage.getItem("organization");
	const storedOrganization = storedOrganizationString ? JSON.parse(storedOrganizationString) : null;
	const OrganizationId = storedOrganization.OrganizationId;
	const [skip, setskip] = useState("0");
	const [top, settop] = useState("100");
	const [totalcount, setTotalcount] = useState(0);
	const { openSnackbar } = useSnackbar();
	const [errors, setErrors] = useState<ErrorFields>({
		AccountName: "",
	});

	const generateCustomUserId = () => {
		const now = new Date();
		const day = String(now.getDate()).padStart(2, "0");
		const month = String(now.getMonth() + 1).padStart(2, "0");
		const year = now.getFullYear();
		const datePart = `${day}${month}${year}`;
		const guidPart = uuidv4();
		return `${datePart}-${guidPart}`;
	};

	const [inputs, setInputs] = useState({
		AccountId: generateCustomUserId(),
		AccountName: "",
		CreatedBy: "",
		AccountType: "",
		CreatedDate: "",
		OrganizationId: "01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863",
		UpdatedBy: "",
		Active: Boolean(true),
	});
	const [organizations, setOrganizations] = useState<any[]>([]);
	const [selectedOrganizationId, setSelectedOrganizationId] = useState(
		"01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863"
	);

	const openPopup = () => {
		setShowPopup(true);
		handleOrganizationDropdownOpen();
	};
	const handleSubmit = (event: any) => {
		event.preventDefault();
		// console.log(inputs);
	};
	const alphanumericRegex = /^[a-zA-Z0-9]*$/;

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		let error = "";
		let processedValue = value.trimStart(); // Remove leading spaces

		if (name === "AccountType") {
			const selectedOrganization = organizations.find((org) => org.Name === value);
			if (selectedOrganization) {
				setInputs((values) => ({
					...values,
					AccountType: value,
					OrganizationId: "01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863", // Placeholder ID
				}));
			}
		} else if (name === "AccountName") {
			// Allow only letters and spaces, remove special characters, numbers, and trim leading spaces
			processedValue = value.replace(/[^a-zA-Z\s]/g, "").replace(/^\s+/, "");

			// Check if the length is less than 5 characters or if the input is only spaces
			if (processedValue.length < 5 || !processedValue.trim()) {
				error = "Account Name must be at least 5 characters and cannot be only spaces.";
			}
		}

		// Update the state with the processed value
		setInputs((prev) => ({ ...prev, [name]: processedValue }));

		// Set error state
		setErrors((prev) => ({ ...prev, [name]: error }));
	};

	// In CreateAccount component

	// const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
	// 	const { name, value } = event.target;
	// 	let error = "";
	// 	let processedValue = value;

	// 	if (name === "AccountType") {
	// 		const selectedOrganization = organizations.find((org) => org.Name === value);
	// 		if (selectedOrganization) {
	// 			setInputs((values) => ({
	// 				...values,
	// 				AccountType: value,
	// 				OrganizationId: "01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863", // Placeholder ID
	// 			}));
	// 		}
	// 	} else if (name === "AccountName") {
	// 		// Allow only letters and spaces, remove special characters and numbers
	// 		processedValue = value.replace(/[^a-zA-Z\s]/g, "");

	// 		// Check if the length is less than 5 characters
	// 		if (processedValue.length < 5) {
	// 			error = "Account Name must be at least 5 characters.";
	// 		}
	// 	}

	// 	// Update the state with the processed value
	// 	setInputs((prev) => ({ ...prev, [name]: processedValue }));

	// 	// Set error state
	// 	setErrors((prev) => ({ ...prev, [name]: error }));
	// };

	const handleSubmitAccount = async (event: any) => {
		event.preventDefault();
		const newErrors: ErrorFields = {};
		let isValid = true;

		(Object.keys(inputs) as (keyof InputFields)[]).forEach((key) => {
			if (!inputs[key]) {
				// newErrors[key] = `${key} is required.`;
				// isValid = false;
			} else if (key === "AccountName") {
				if (inputs[key].length < 5) {
					newErrors[key] = "Account Name must be at least 5 characters.";
					isValid = false;
				}
				if (/[^a-zA-Z\s]/g.test(inputs[key])) {
					newErrors[key] = "Account Name can only contain letters and numbers.";
					isValid = false;
				}
			}
		});

		if (!inputs.AccountName) {
			setSnackbarMessage("Account Name is required.");
			setSnackbarSeverity("error");
			setSnackbarOpen(true);
			return;
		}
		const newInputs = {
			...inputs,
			AccountId: generateCustomUserId(), // Generate a new ID on submit
			Active: true,
		};
		setErrors(newErrors);
		if (isValid) {
			setLoading(true);
			try {
				SubmitCreateAccount(
					setLoading,
					setShowPopup,
					setModels,
					newInputs,
					OrganizationId,
					skip,
					top,
					setTotalcount,
					openSnackbar,
					orderByField,
					filters
				);
				//openSnackbar("User  created successfully!", "success");
			} catch (error) {
				//openSnackbar("Failed to create user .", "error");
			}
		}
	};

	const isAccountNameValid = (name: string): boolean => {
		const processedValue = name.trimStart().replace(/[^a-zA-Z\s]/g, "");
		return processedValue.length >= 5 && processedValue.trim() !== "";
	};

	const handleOrganizationDropdownOpen = async () => {
		try {
			const response = await getAllOrganizations(setOrganizations, setLoading);
		} catch (error: any) {
			console.error("Error fetching organizations:", error);
			setSnackbarMessage(`Error fetching organizations: ${error.message}`);
			setSnackbarSeverity("error");
			setSnackbarOpen(true);
		}
	};
	const handleSelectChange = (event: any) => {
		const selectedName = event.target.value;
		const selectedOrganization = organizations.find((org) => org.Name === selectedName);
		if (selectedOrganization) {
			setSelectedOrganizationId("01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863");
		}
		console.log(selectedOrganizationId);
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	return (
		<div>
			{/*<div>
				 <h1>Create Account Page</h1> 
				 <button
					onClick={openPopup}
					className="userButton"
				>
					Create Account
				</button>
			</div> */}

			{showPopup ? (
				<div
					className="userEdit-popup"
					style={{ height: "37vh", right: "500px", top: "200px", width: "349px !important" }}
				>
					<div>
						<h1>Create Account</h1>
						<svg
							onClick={() => setShowPopup(false)}
							className="close-icon"
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="24"
							height="24"
							viewBox="0 0 50 50"
						>
							<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
						</svg>
					</div>
					<form onSubmit={handleSubmit}>
						{/* <TextField
							//fullWidth
							label="Account Name"
							name="AccountName"
							onChange={handleChange}
							variant="standard"
							required
							//value={inputs.AccountName}
							helperText={errors.AccountName}
							inputProps={{ maxLength: 50 }}
							error={!!errors.AccountName}
							sx={{
								"& .MuiFormHelperText-root": {
									color: errors.AccountName ? "red" : "inherit",
								},
								marginBottom: "16px",
							}}
						/> */}
						<TextField
							name="AccountName"
							value={inputs.AccountName}
							onChange={handleChange}
							// onBlur={handleBlur}
							label="AccountName*"
							helperText={errors.AccountName}
							variant="standard"
							error={!!errors.AccountName}
							inputProps={{ maxLength: 50 }}
							sx={{
								"& .MuiFormHelperText-root": {
									color: errors.AccountName ? "red" : "inherit",
								},
								width: "300px",
								marginBottom: "14px",
							}}
						/>
						{/*	Account Type:
						 <select
							name="AccountType"
							style={{ width: "100px" }}
						>
							{organizations.map((org) => (
								<option
									key={org.OrganizationId}
									value={org.Name}
								>
									{org.Name}
								</option>
							))}
						</select> 
						<select
							name="AccountType"
							style={{ width: "100px", height: "50px" }}
							onChange={handleChange}
							value={inputs.AccountType}
						>
							{organizations.map((org) => (
								<option
									key={org.OrganizationId}
									value={org.Name}
								>
									{org.Name}
								</option>
							))}
						</select> */}
					</form>
					<div style={{ marginLeft: "115px" }}>
						<button
							style={{
								position: "relative",
								background: "#fff",
								color: "#007bff",
								border: "1px solid #007bff",
								marginRight: "27px",
								right: "90px",
								top: "25px",
							}}
							onClick={() => setShowPopup(false)}
							className="userButton"
						>
							Cancel
						</button>
						<button
							style={{
								position: "relative",
								right: "-65px",
								top: "-8px",
								width: "80px",
								backgroundColor: !isAccountNameValid(inputs.AccountName) ? "#aaaef2" : "#1c2dd8",
							}}
							className="userButton"
							onClick={handleSubmitAccount}
							disabled={!isAccountNameValid(inputs.AccountName)}
						>
							Save
						</button>
					</div>
				</div>
			) : (
				""
			)}
			<Snackbar
				style={{ zIndex: "9999", position: "relative", top: "-50px", width: "500px", right: "-600px" }}
				open={snackbarOpen}
				autoHideDuration={4000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					sx={{ width: "100%" }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default CreateAccount;
