import React from "react"
import { lazy } from "react"
import { Navigate, Outlet, useRoutes } from "react-router-dom"
import { useLocation, useNavigate } from "react-router"
import Home from "../components/common/Home"
// import ThemeSettings from "../components/settings/ThemeSettings"
import NotificationSettings from "../components/settings/NotificationSettings"
import ProfileSettings from "../components/settings/ProfileSettings"
import Builder from "../components/guide/Builder"
import { Dashboard, Domain } from "@mui/icons-material"

import AdminList from "../components/organization/AdminList"
import AdminPage from "../components/organization/AdminPage"
import Translater from "../components/Multilingual"
import DomainSettings from "../components/settings/DomainSettings"
import { Themes } from "../assets/icons/icons"
import { useAuth } from "../components/auth/AuthProvider"

//import { toLanguage } from "../components/adminMenu/AdminMenu"


const AccountCreate = lazy(() => import("../components/account/AccountCreate"))
const AuditLogList = lazy(() => import("../components/auditLog/AuditLogList"))
const Login = lazy(() => import("../components/login/login"))
const OrganizationList = lazy(() => import("../components/organization/OrganizationList"))
const AccountsList = lazy(() => import("../components/account/AccountList"))
const GuidesList = lazy(() => import("../components/guide/GuideList"))
const UserList = lazy(() => import("../components/user/UserList"))
const Accountsetting = lazy(() => import("../components/settings/AccountSettings"))
const Teamsetting = lazy(() => import("../components/settings/TeamSettings"))
const Domainsetting = lazy(() => import("../components/settings/DomainSettings"))
const Settings = lazy(() => import("../components/settings/Settings"))
const InstallSetting = lazy(() => import("../components/settings/InstallSettings"))
const BillingSetting = lazy(() => import("../components/settings/BillingSettings"))
const RightSettings = lazy(() => import("../components/settings/RightSettings"))
const PageNotFound = lazy(() => import("../components/PageNotFound"));
const SuperAdminAuditLogList = lazy(() => import("../components/auditLog/SuperAdminAuditLogList"))
const RegistrationPage = lazy(() => import("../components/RegistrationPage/RegistrationPage"))
const Callback = lazy(() => import("../services/Callback"))
const DashBoard = lazy(() => import("../components/dashboard/Dashboard"))
const FileList = lazy(() => import("../components/fileManagement/FileList"))

const ThemeSettings = lazy(() => import("../components/settings/ThemeSettings"));
//const DefaultThemes = lazy(() => import("../components/settings/DefaultThemes"));

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { user } = useAuth();
	return user ? <>{children}</> : <Navigate to="/" />;
};

export const UnAuthRouteComponent: any = () => {
	const routes = useRoutes([
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/callback",
			element: <Callback />,
		},
		{
			path: "/",
			element: <Home/>,
		},
		
	]);

  return routes;
};

export const SuperAdminRouteComponent: any = () => {
	const routes = useRoutes([
		{
			path: "superadmin/organizations",
			element:  <OrganizationList />,
		},
		{
			path: "/superadmin/adminPage",
			element: <AdminPage />,
		},
		{
			path: "/superadmin/adminList",
			element:<AdminList /> ,
		},
		
	]);

  return routes;
};


const Routing = () => {	
	const navigate = useNavigate();
	const userType = localStorage.getItem("userType");
	// const token = localStorage.getItem("access_token");
	// if (!token)
	// {
	// 	navigate('/login')		
	// }

	const routes = useRoutes([
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/",
			element: <Home />,
		},

		{
			path: "/createaccount", // /createaccount/AccountID
			element: <AccountCreate />,
		},
		{
			path: "/guides",
			element: <GuidesList />,
		},
		{
			path: "/accounts",
			element: <AccountsList />,
		},		
		{
			path: "/auditlog",
			element: userType === "admin" ? <AuditLogList /> : null,
		},
		{
			path: "/user",
			element: userType === "admin" ? <UserList /> : null,
		},
		{
			path: "/filelist",
			element: userType === "admin" ? <FileList /> : null,
		},
		{
			path: "/guide",
			element: <GuidesList />,
		},
		// {
		// 	path: "/Engagement",
		// 	element: <Engagement />,
		// },
		{
			path: "/ThemeSettings",
			element: <ThemeSettings />,
		},
		// {
		// 	path: "/DefaultThemes",
		// 	element: <DefaultThemes />,
		// },
		// {
		// 	path: "/Registration",
		// 	element: <RegistrationPage />,
		// },
		// {
		// 	path: "/settings",
		// 	element:
		// 	  userType === "admin" ? (
		// 		<div>
		// 		  <Settings />
		// 		  <ProfileSettings />
		// 		</div>
		// 	  ) : (
		// 		null
		// 	  ),
		//   },
		// {
		// 	path: "/settings/account",
		// 	element: userType === "admin" ? <Accountsetting /> : null,
		// },
		{
			path: "/settings/rights",
			element:
				userType === "admin" ? (
					<div>
						<Settings />
						<RightSettings />
					</div>
				) : null,
		},

		{
			path: "/settings/team",
			element:
				userType === "admin" ? (
					<div>
						<Settings/>
					<Teamsetting /> 
					</div>):( null
				),
		},
		{
			path: "/theme",
			element: userType === "admin" ? <ThemeSettings /> : null,
		},
		{
			path: "/settings/domain",
			element:
				userType === "admin" ? (
					<div>
						<Settings  />
						<DomainSettings />
					</div>
				) : null,
		},
		{
			path: "/settings/install",
			element: userType === "admin" ? <InstallSetting /> : null,
		},
		{
			path: "/notifications",
			element: userType === "admin" ? <NotificationSettings /> : null,
		},
		{
			path: "/settings/billing",
			element: userType === "admin" ? <BillingSetting /> : null,
		},
		{
			path: "/Builder",
			element: <Builder />,
		}
		
		// {
		// 	path: "/Multilingual",
		// 	element: <Translater selectedLanguageProp="" />

		// }
	]);
	return routes
}
export default Routing
