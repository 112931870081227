import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { Container, List, ListItem, ListItemText, Typography, Box } from "@mui/material";
// import { createGlobalStyle } from 'styled-components';

// const GlobalStyle = createGlobalStyle<{ isHidden: boolean }>`
//   body {
//     overflow: ${({ isHidden }) => (isHidden ? 'hidden' : 'auto')};
//   }
// `;

const Settings = () => {
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  const location = useLocation();
  const isHidden = location.pathname === "/settings/accounts";

  useEffect(() => {
    const unsubscribe = subscribe(setSidebarOpen);
    return () => unsubscribe();
  }, []);

  return (
		<>
			{/* <GlobalStyle isHidden={isHidden} /> */}
			<Container
				maxWidth="xl"
				style={{ backgroundColor: "transparent" }}
			>
				<Box sx={{ marginLeft: sidebarOpen ? "230px" : "39px", marginTop: "20px" }}>
					<Typography
						variant="h4"
						sx={{
							width: "54px",
							height: "18px",
							gap: "0px",
							opacity: 0.5,
							fontFamily: "Poppins",
							fontSize: "12px",
							fontWeight: 400,
							lineHeight: "18px",
							letterSpacing: "-0.11428570002317429px",
							textAlign: "left",
							color: "rgba(32, 34, 36, 1)",
							padding: "4px",
						}}
					>
						Settings
					</Typography>
					<List sx={{ width: "138px", gap: "4px" }}>
						{[
							{ text: "Account", path: "/settings/accounts" },
							{ text: "Team", path: "/settings/team" },
							{ text: "Roles", path: "/settings/user" },
							{ text: "Domain", path: "/settings/domains" },
							{ text: "Rights", path: "/settings/rights" },
							{ text: "Alerts", path: "/settings/alerts" },
							{ text: "Billing", path: "/settings/billing" },
							{ text: "Installation", path: "/settings/install" },
						].map((item, index) => (
							<ListItem
								key={index}
								component={NavLink}
								to={item.path}
								sx={{
									padding: "6px 8px",
									marginBottom: "4px",
									borderRadius: "6px",
									backgroundColor: location.pathname === item.path ? "rgba(95, 158, 160, 1)" : "transparent",
									"&:hover": {
										backgroundColor: "rgba(0, 0, 0, 0.08)",
									},
									"&.active": {
										backgroundColor: "rgba(95, 158, 160, 1)",
										color: "#ffffff",
									},
									"&.active .MuiTypography-root": {
										color: "#ffffff",
									},
								}}
							>
								<ListItemText
									primary={
										<Typography
											sx={{
												fontFamily: "Poppins",
												fontSize: "14px",
												fontWeight: 400,
												lineHeight: "21px",
												letterSpacing: "0.3px",
												textAlign: "left",
												color: location.pathname === item.path ? "#ffffff" : "#202224",
												marginLeft: "-2px",
											}}
										>
											{item.text}
										</Typography>
									}
								/>
							</ListItem>
						))}
					</List>
				</Box>
			</Container>
		</>
	);
};

export default Settings;
