import {adminApiService,userApiService} from "./APIService";
import { updateProfile, GetUserDetails } from "../models/ProfileSettingPage";
import { Status } from "../models/Status";
export const UpdateProfile = async (userData: updateProfile): Promise<Status> => {
    try {
        const response = await userApiService.post<Status>("/User/UpdateProfile", userData);
        return response.data;
    } catch (error) {
        console.error("Error updating profile:", error);
        throw error;
    }
};
export const GetUserDetail = async (): Promise<GetUserDetails[]> => {
	try {
		const response = await adminApiService.get<GetUserDetails[]>(`/User/GetAllUsersForAllOrganizations`);
		return response.data;
	} catch (error) {
		console.error(`Error fetching GetUserDetails :`, error);
		throw error;
	}
};