
import {adminApiService,userApiService} from "./APIService";
import { Organization } from "../models/Organization";
import { AnySoaRecord } from "dns";
import { useNavigate} from "react-router-dom";

// -------------------------------Organization Apis------------------------------


const adminUrl = process.env.REACT_APP_ADMIN_API
const userUrl = process.env.REACT_APP_USER_API

export const getOrganization = async (): Promise<Organization[]> => {
	try {
		const response = await adminApiService.get<Organization[]>("Organization/GetOrganizations");
		return response.data;
	} catch (error) {
		console.error("Error fetching organizations:", error);
		throw error;
	}
};

export const getAllOrganizations = async (setOrganizations: any, setLoading: any) => {
	try {
		const response = await adminApiService.get("/Organization/GetOrganizations");
		const apiData = response.data;

		if (Array.isArray(apiData)) {
			setOrganizations(apiData);
		} else {
			console.error("Invalid data format from API:", apiData);
		}
	} catch (error) {
		console.error("Error fetching organizations:", error);
	} finally {
		setLoading(false);
	}
};


// Function to fetch organization by ID
export const getOrganizationById = async (OrganizationId: string): Promise<Organization> => {
	try {
		const response = await adminApiService.get<Organization>(`/Organization/GetOrganizationById?organizationId=${OrganizationId}`);
		return response.data;
	} catch (error) {
		console.error(`Error fetching organization ${OrganizationId}:`, error);
		throw error;
	}
};




// Function to create a new organization
export const createOrganization = async (
	setLoading: any,
	setShowPopup: any,
	setModels: any,
	setShowEditPopup: any,
	inputs: any,
	setSnackbarMessage: any,
	setSnackbarSeverity: any,
	setSnackbarOpen: any,
	onSuccess: () => void // Add a success callback
  ): Promise<void> => {
	try {
	  const response = await fetch(`${adminUrl}/Organization/CreateOrganization`, {
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
		body: JSON.stringify(inputs),
	  });
  
	  if (!response.ok) {
		setSnackbarMessage("Organization not created successfully");
		setSnackbarSeverity("error");
		setSnackbarOpen(true);
		throw new Error("Network response was not ok");
	  }
  
	  // Assuming 204 No Content is a successful response
	  console.log("Organization created successfully");
	  setShowEditPopup(false);
	  setShowPopup(false);
	  setSnackbarMessage("Organization created successfully");
	  setSnackbarSeverity("success");
		setSnackbarOpen(true);
		setTimeout(onSuccess, 5000);
  
	  // Call the success callback
	  onSuccess();
	} catch (error) {
	  setSnackbarMessage(`Error creating Organization: ${error}`);
	  setSnackbarSeverity("error");
	  setSnackbarOpen(true);
	  console.error("Error creating Organization:", error);
	}
  };
  

  export const updateOrganization = async (
	setLoading: any,
	setModels: any,
	setShowEditPopup: any,
	userDetails: any,
	setSnackbarMessage: any,
	setSnackbarSeverity: any,
	setSnackbarOpen: any,
	onSuccess: () => void // Add a success callback
  ) => {
	try {
	  const response = await fetch(`${adminUrl}/Organization/UpdateOrganization`, {
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
		body: JSON.stringify(userDetails),
	  });
  
	  if (response.ok) {
		setSnackbarMessage("Organization Updated successfully");
		setSnackbarSeverity("success");
		  setSnackbarOpen(true);
		  
  
		// Delay before calling the success callback
		setTimeout(onSuccess, 2000); // 2 seconds delay
	  } else {
		setSnackbarMessage("Organization not Updated successfully");
		setSnackbarSeverity("error");
		setSnackbarOpen(true);
	  }
	} catch (error) {
	  setSnackbarMessage(`Failed to update organization: ${error}`);
	  setSnackbarSeverity("error");
	  setSnackbarOpen(true);
	  console.error("Failed to update organization:", error);
	}
  };
  
// Function to activate an organization
export const activateOrganization = async (
	organizationId: string,
	checkedOne: any,
	setShowActivatePopup: any,
	setModels: any,
	setLoading: any,) =>  {
		fetch(`${adminUrl}/Organization/ActivateOrganization?organizationId=${organizationId}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(organizationId),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				// Assuming 204 No Content is a successful response
				console.log("User Deleted successfully");
			

			})
			.catch((error) => {
				console.error("Error Deleting user:", error);
			});
	
};

// Function to deactivate an organization
export const deactivateOrganization = async (
	organizationId: string,
	checkedOne: any,
	setShowDeactivatePopup:any,
	setModels: any,
	setLoading: any
)=> {
	fetch(`${adminUrl}/Organization/DeActivateOrganization?organizationId=${organizationId}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(organizationId),
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			// Assuming 204 No Content is a successful response
			console.log("User Deleted successfully");
		
		})
		.catch((error) => {
			console.error("Error Deleting user:", error);
		});
};

export const deleteOrganization = async (
	organizationId: string,
	setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>,
	getOrganizations: (setModels: any, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void,
	setModels: React.Dispatch<React.SetStateAction<any[]>>,
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
	try {
	  const response = await fetch(`${adminUrl}/Organization/DeleteOrganization?organizationId=${organizationId}`, {
		method: "DELETE",
		headers: {
		  "Content-Type": "application/json",
		},
	  });
  
	  if (!response.ok) {
		throw new Error("Network response was not ok");
	  }
  
	  // Assuming 204 No Content is a successful response
	  console.log("Organization deleted successfully");
	  setShowDeletePopup(false);
	  getOrganizations(setModels, setLoading);
	} catch (error) {
	  console.error("Error deleting organization:", error);
	}
  };


export const getOrganizationByDomain = async (domainName: string): Promise<Organization> => {
    try {
        const response = await adminApiService.get<Organization>(`/Organization/GetOrganizationByDomain?domainName=${domainName}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching organization by domain ${domainName}:`, error);
        throw error;
    }
};

export const getOrganizations = async (
    setModels: any,
    setLoading: any,
    skip: number,
    top: number,
    setTotalCount: any,
	sortModel: any,
	filters:any
) => {
    try {
       
		const orderByFields = sortModel.map((sort: any) => `${sort.field}${sort.sort === 'desc' ? ' desc' : ''}`).join(',');
		const requestData = {
            skip,
            top,
            filters:filters,
            orderByFields: orderByFields || 'Name' // Default to 'Name' if no sort fields are provided
        };

        const response = await adminApiService.post(`/Organization/GetOrganizations`, requestData);
        const apiData = response.data.results;
        setTotalCount(response.data._count);

        if (Array.isArray(apiData)) {
            setModels(apiData);
        } else {
            console.error("Invalid data format from API:", apiData);
        }
    } catch (error) {
        console.error("Error fetching organizations:", error);
	}
	finally {
		setLoading(false)
	}
};


export const fetchOrganizations = async (setModelsData: any, setLoading: any) => {
	try {
		const response = await adminApiService.get("/Organization/GetAllOrganizations");
		const apiData = response.data;

		if (Array.isArray(apiData)) {
			setModelsData(apiData)
			
		} else {
			console.error("Invalid data format from API:", apiData);
		}
	} catch (error) {
		console.error("Error fetching organizations:", error);
		
	}
	finally {
		setLoading(false);
	}

};




export const getOrganizationsData = async (setModels:any,setLoading:any)=> {
	try {
		const response = await adminApiService.get("/Organization/GetAllOrganizations");
		const apiData = response.data;

		if (Array.isArray(apiData)) {
			return apiData;
		} else {
			console.error("Invalid data format from API:", apiData);
			return [];
		}
	} catch (error) {
		console.error("Error fetching organizations:", error);
		return [];
	}
};