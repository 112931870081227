import React, { useState, useEffect } from "react";
import {
	GridColDef,
	GridRenderCellParams,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	DataGrid,
	GridPaginationModel,
	GridColumnMenuProps,
	GridColumnsManagement,
} from "@mui/x-data-grid";
import {
	Button,
	Menu,
	MenuItem,
	FormControlLabel,
	Popover,
	Typography,
	IconButton,
	Switch,
	Container,
	Box,
	Snackbar,
	Alert,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnRoundedIcon from "@mui/icons-material/ToggleOnRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import loader from "../../assets/loader.gif";
import { userData } from "./userData";
import CustomGrid from "../common/Grid";
import PersonOffSharpIcon from "@mui/icons-material/PersonOffSharp";
import axios from "axios";
import { MarkEmailRead } from "@mui/icons-material";
import EditUser from "./UserEdit";
import { useSnackbar } from "../../SnackbarContext";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyIcon from "@mui/icons-material/Key";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import UserCreate from "./UserCreate";
import type { DatePickerProps } from "antd";
import Tooltip from "@mui/material/Tooltip";
import DraftsIcon from "@mui/icons-material/Drafts";
import {
	DeleteUserDetails,
	SubmitCreateUser,
	Submitdisableuser,
	fetchUserDataFromApi,
	fetchUsersList,
	confirmEmail,
	BlockUser,
	UnblockUser,
	deactivateUser,
	activateUser,
} from "../../services/UserService";

import UserPasswordReset from "./UserPasswordReset";
//import CustomColumnMenu from "../CustomColumnMenu";
import UserCustomColumnMenu from "./UserCustomColumnMenu";
import styles from "./UserStyles.module.scss";
import { error } from "console";
// import "./UserStyle.module.scss";

interface CustomDataGridProps extends React.ComponentProps<typeof DataGrid> {
	components?: {
		columnMenu?: React.ComponentType<GridColumnMenuProps>;
	};
}

interface Model {
	FullName: string | null;
	EmailId: string;
	ContactNumber: string | null;
	CreatedOn: string;
	Manager: string;
	UserId: string;
}
const UserList: React.FC = () => {
	const [models, setModels] = useState<Model[]>([]);
	const [loading, setLoading] = useState(false);
	const [inputs, setInputs] = useState({}); //useState<DataModel>() // //
	const [emailiddelete, setemailiddelete] = useState("");
	const [useridedit, setUserIdEdit] = useState("");
	const [useridreset, setUserIdReset] = useState("");
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [users, setUsers] = useState("");
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [userName, setUserName] = useState("");
	const [helperText, setHelperText] = useState("	");
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const storedOrganizationString = localStorage.getItem("organization");
	const storedOrganization = storedOrganizationString ? JSON.parse(storedOrganizationString) : null;
	const OrganizationId = storedOrganization.OrganizationId;
	const timeZone = storedOrganization ? storedOrganization.TimeZone : "UTC";
	const [totalcount, setTotalcount] = useState(0);
	const { openSnackbar } = useSnackbar();
	const [usersEmails, setUsersEmails] = useState<string[]>([]);
	const [searchText, setSearchText] = useState("");
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		page: 0,
		pageSize: 10,
	});
	const [skip, setskip] = useState(0);
	const [top, settop] = useState(0);
	const [newpassword, setNewpassword] = useState("");
	const [orderByFields, setOrderByFields] = useState("");
	const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
	const [sortModel, setSortModel] = useState([]);
	const [columnname, setColumnname] = useState("");
	const [filters, setFilters] = useState([]);
	const handleSortModelChange = (model: any) => {
		setSortModel(model);
		const orderByField = model
			.map((item: any) =>
				item.sort === "" || item.sort === "asc" || (item.sort === "desc" && item.field === "FullName")
					? `FirstName desc`
					: item.sort === "desc" && item.field !== "FullName"
					? `${item.field} desc`
					: item.field
			)
			.join(", ");
		//console.log(orderByField);
		setOrderByFields(orderByField);
		// Fetch data with new sort order
		//GetAccountsList(setFilteredRows, setLoading, OrganizationId, paginationModel.page * paginationModel.pageSize, paginationModel.pageSize, setTotalcount, orderByFields);
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByField, filters);
	};

	//const [top, setTop] = useState(paginationModel.pageSize);
	let skipss: Number;
	let topss: Number;

	const handleFirstnameFocus = () => {
		if (firstName.length < 2) {
			setHelperText("First Name is required");
		} else {
			setHelperText("");
		}
		//console.log(helperText);
	};
	const handleLastnameFocus = () => {
		if (lastName.length < 2) {
			setHelperText("Last Name is required");
		} else {
			setHelperText("");
		}
		//	console.log(helperText);
	};
	const handleUsernameFocus = () => {
		if (userName.length < 2) {
			setHelperText("User Name is required");
		} else {
			setHelperText("");
		}
		//console.log(helperText);
	};
	const handleEmailFocus = () => {
		if (firstName.length < 2) {
			setHelperText("User Name is required");
		} else {
			setHelperText("");
		}
		//	console.log(helperText);
	};
	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};
	const handleSearch = (value: string[]) => {
		setSearchText(value.join(" "));
	};

	const MatEdit = (data: any) => {
		//const [isEmailConfirmed, setIsEmailConfirmed] = useState(data.emailConfirmed);
		const handleMailClick = (userid: string) => {
			//setIsEmailConfirmed(true);
			try {
				if (data.emailConfirmed) {
					openSnackbar("Email is already confirmed", "error");
				} else {
					confirmEmail(
						setModels,
						setLoading,
						OrganizationId,
						skip,
						top,
						setTotalcount,
						userid,
						openSnackbar,
						orderByFields,
						filters
					);
				}

				setLoading(false);
			} catch (error) {}

			//	console.log("Email confirmed status changed to:", !isEmailConfirmed);
		};
		const Blockuser = (userid: string) => {
			// some action m;';
			try {
				BlockUser(
					setModels,
					setLoading,
					OrganizationId,
					skip,
					top,
					setTotalcount,
					userid,
					openSnackbar,
					orderByFields,
					filters
				);

				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		const UnBlockuser = (userid: string) => {
			// some action m;';
			try {
				UnblockUser(
					setModels,
					setLoading,
					OrganizationId,
					skip,
					top,
					setTotalcount,
					userid,
					openSnackbar,
					orderByFields,
					filters
				);

				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		const Activateuser = (userid: string) => {
			try {
				activateUser(
					setModels,
					setLoading,
					OrganizationId,
					skip,
					top,
					setTotalcount,
					data.userid,
					openSnackbar,
					orderByFields,
					filters
				);

				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		const DeActivateuser = (userid: string) => {
			try {
				deactivateUser(
					setModels,
					setLoading,
					OrganizationId,
					skip,
					top,
					setTotalcount,
					data.userid,
					openSnackbar,
					orderByFields,
					filters
				);

				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		const ResetPassword = (userid: string) => {
			//console.log(userid);
			setUserIdEdit(userid);
			setShowResetPasswordPopup(true);
			// some action
			//console.log(type);
		};

		const handleDeleteClick = (emailId: string) => {
			setShowDeletePopup(true);
			console.log(emailId);
			setemailiddelete(emailId);
		};
		const handleeditclick = (userid: string) => {
			setShowEditPopup(true);
			console.log(userid);
			setUserIdEdit(userid);
		};
		const handleSwitchChange = (userid: string) => {
			console.log(userid);
			setUserIdEdit(userid);
			Submitdisableuser(setLoading, setModels, userid);
		};
		const handleClose = () => {
			setAnchorEl(null);
			setShowDeletePopup(false);
		};

		return (
			<div>
				<FormControlLabel
					control={
						<Tooltip
							title={!data.emailConfirmed ? "Confirm Email" : "Email Confirmed"}
							arrow
							placement="top"
						>
							<IconButton
								className={styles.IconButton}
								color="secondary"
								aria-label="email"
								onClick={() => handleMailClick(data.userid)}
							>
								{data.emailConfirmed === true ? (
									<MailIcon style={{ color: "blue" }} />
								) : (
									<DraftsIcon style={{ color: "blue" }} />
								)}
							</IconButton>
						</Tooltip>
					}
					label={""}
				/>
				<FormControlLabel
					control={
						<Tooltip
							title="Edit"
							arrow
							placement="top"
						>
							<IconButton
								className={styles.IconButton}
								color="secondary"
								aria-label="edit"
								onClick={() => handleeditclick(data.userid)}
							>
								<EditIcon style={{ color: "blue" }} />
							</IconButton>
						</Tooltip>
					}
					label={""}
				/>
				{/* Uncomment if you want to use the delete icon */}
				{/* <FormControlLabel
    control={
      <Tooltip title="Delete" arrow>
        <IconButton
          color="secondary"
          aria-label="delete"
          onClick={() => handleDeleteClick(data.emailId)}
        >
          <DeleteIcon style={{ color: "blue" }} />
        </IconButton>
      </Tooltip>
    }
    label={""}
  /> */}
				<FormControlLabel
					control={
						<Tooltip
							title={data.activateuser ? "Activate Member" : "DeActivate Member"}
							arrow
							placement="top"
						>
							<IconButton
								className={styles.IconButton}
								color="primary"
								aria-label={data.activateuser ? "deactivate Member" : "activate Member"}
								onClick={() => {
									if (!data.activateuser) {
										DeActivateuser(data.userid);
									} else {
										Activateuser(data.userid);
									}
								}}
							>
								{!data.activateuser ? (
									<ToggleOnRoundedIcon style={{ color: "blue" }} />
								) : (
									<ToggleOffOutlinedIcon style={{ color: "blue" }} />
								)}
							</IconButton>
						</Tooltip>
					}
					label=""
				/>

				<FormControlLabel
					control={
						<Tooltip
							title={data.isblocked ? "UnBlock Member" : "Block Member"}
							arrow
							placement="top"
						>
							<IconButton
								className={styles.IconButton}
								// style={{ width: "30px" }}
								color="secondary"
								aria-label="block user"
								onClick={() => {
									if (data.isblocked) {
										UnBlockuser(data.userid);
									} else {
										Blockuser(data.userid);
									}
								}}
							>
								{!data.isblocked ? (
									<PersonOutlineOutlinedIcon style={{ color: "blue" }} />
								) : (
									<PersonOffSharpIcon style={{ color: "blue" }} />
								)}
							</IconButton>
						</Tooltip>
					}
					label={""}
				/>
				<FormControlLabel
					control={
						<Tooltip
							title="Reset Password"
							arrow
							placement="top"
						>
							<IconButton
								className={styles.IconButton}
								color="secondary"
								aria-label="reset password"
								onClick={() => ResetPassword(data.userid)}
							>
								<KeyIcon style={{ color: "blue" }} />
							</IconButton>
						</Tooltip>
					}
					label={""}
				/>
			</div>
		);
	};

	const columns: GridColDef[] = [
		{
			field: "FullName",
			headerName: "Full Name",
			width: sidebarOpen ? 100 : 100,

			filterable: false,
			disableColumnMenu: true,
			sortable: true,
			renderCell: (params) => {
				const { FirstName, LastName } = params.row;
				return (
					<div>
						{FirstName} {LastName}
					</div>
				);
			},
		},

		{ field: "EmailId", headerName: "Email Id", width: sidebarOpen ? 150 : 250 },
		{ field: "ContactNumber", headerName: "Contact Number", width: sidebarOpen ? 150 : 200 },

		{
			field: "CreatedDate",
			headerName: "Created On",
			width: sidebarOpen ? 150 : 150,

			disableColumnMenu: true,
			renderCell: (params) => {
				const date = params.value ? params.value.split("T")[0] : "";
				return <div>{date}</div>;
			},
		},
		{
			field: "AdminDeactivated",
			headerName: "User Status",
			width: 150,

			renderCell: (params) => {
				const { AdminDeactivated } = params.row;
				return <div>{AdminDeactivated ? "InActive" : "Active"}</div>;
			},
		},
		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 250,

			disableColumnMenu: true,
			renderCell: (params) => {
				const emailConfirmed = params.row.EmailConfirmed || false;
				const emailId = params.row.EmailId || false;
				const userid = params.row.UserId || false;
				const isblocked = params.row.IsBlocked || false;
				const activateuser = params.row.AdminDeactivated || false;
				return (
					<div
						className="d-flex justify-content-between align-items-center"
						style={{ cursor: "pointer" }}
					>
						<MatEdit
							emailConfirmed={emailConfirmed}
							emailId={emailId}
							index={params.row.id}
							userid={userid}
							isblocked={isblocked}
							activateuser={activateuser}
						/>
					</div>
				);
			},
		},
	];

	const onPageChange = (newPage: number) => {
		//	console.log("Page changed to:", newPage);
	};
	const onPageSizeChange = (newPageSize: number) => {
		//console.log("Page size changed to:", newPageSize);
	};

	const fetchusers = (skip: any, top: any) => {
		// setLoading(true);
		setLoading(true);
		// const skipcount = paginationModel.pageSize || 15;
		// const limitcount = paginationModel.page * skipcount;
		// setskip(limitcount.toString());
		// const skips = limitcount;
		// const tops = skipcount + limitcount;

		//fetchUserDataFromApi(setModels, setLoading);
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		// setLoading(false);
	};
	const fetchData = async () => {
		const skipcount = paginationModel.pageSize || 10;
		//(paginationModel.pageSize, "pageSize");
		const limitcount = paginationModel.page * skipcount;
		const skips = limitcount;
		const top = paginationModel.pageSize;
		setskip(skips);
		settop(top);
		await fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, filters);
		//console.log("Models after fetch:", models);
	};
	// Empty dependency array to run only once on component mount
	useEffect(() => {
		fetchData();
	}, [paginationModel]);

	// useEffect(() => {
	// 	if (skip === "0") {
	// 		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount);
	// 	} else {
	// 		fetchusers(skip, top);
	// 	}
	// }, []);
	useEffect(() => {
		if (!models.length) {
			setUsersEmails(models.map((user) => user.EmailId));
		}
	}, [models]);

	const handleNextButtonClick = () => {
		const newSkip = (paginationModel.page + 1) * paginationModel.pageSize;
		// setskip(newSkip.toString());
		// skipss = newSkip;
		const newTop = newSkip + paginationModel.pageSize;
		// setTop(newTop.toString());
		// topss = newTop;
		setPaginationModel((prev) => ({ ...prev, page: prev.page + 1 }));
		fetchusers(newSkip, newTop);
		//fetchusers(newSkip, newTop);
	};

	const handlePreviousButtonClick = () => {
		const newSkip = (paginationModel.page - 1) * paginationModel.pageSize;
		// setskip(newSkip.toString());
		// skipss = newSkip;
		const newTop = newSkip + paginationModel.pageSize;
		// setTop(newTop.toString());
		// topss = newTop;
		setPaginationModel((prev) => ({ ...prev, page: prev.page - 1 }));
		fetchusers(newSkip, newTop);
	};

	useEffect(() => {
		if (models.length !== 0) {
			setLoading(false);
		}
	}, [models]);
	const [showPopup, setShowPopup] = useState(false);
	const [showeditPopup, setShowEditPopup] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);

	const openPopup = () => {
		setShowPopup(true);
	};
	const openEditPopup = () => {
		setShowEditPopup(true);
	};
	const handleDownloadExcel = async () => {
		try {
			// const response = await fetchorganizationlistData()
			// const url = process.env.REACT_APP_BASE_URL
			// if (response.Msg_Code === "802.171.251.01" && response.File_Path) {
			// 	const downloadLink = document.createElement("a")
			// 	downloadLink.href = `${url}${response.File_Path}`
			// 	downloadLink.download = "excel_data.xlsx"
			// 	document.body.appendChild(downloadLink)
			// 	downloadLink.click()
			// 	document.body.removeChild(downloadLink)
			// } else {
			// 	console.error("Invalid response from the server:", response)
			// 	throw new Error("Invalid response from the server")
			// }
		} catch (error) {
			console.error("Error downloading Excel:", error);
		}
	};
	const CustomToolbar: React.FC<any> = () => {
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

		const handleExportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleExportMenuClose = () => {
			setAnchorEl(null);
		};

		const handleDownloadExcelClick = () => {
			handleExportMenuClose();
		};

		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</GridToolbarContainer>
				<Button
					aria-controls="export-menu"
					aria-haspopup="true"
					onClick={handleExportMenuClick}
					style={{ marginLeft: "10px" }}
					startIcon={<SaveAltIcon />}
				>
					Export
				</Button>
				<Menu
					id="export-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleExportMenuClose}
				>
					<MenuItem
						onClick={() => {
							handleDownloadExcel();
							handleExportMenuClose();
						}}
					>
						Download Excel
					</MenuItem>
				</Menu>
			</div>
		);
	};

	const handleDeleteuser = () => {
		try {
			DeleteUserDetails(emailiddelete, setShowDeletePopup, fetchUserDataFromApi, setModels, setLoading);
			openSnackbar("User  Deleted successfully!", "success");
		} catch (error) {
			openSnackbar("Failed to Delete user .", "error");
		}
	};

	const handleChange = (event: any) => {
		setFirstName(event.target.value);
		handleFirstnameFocus();
		const name = event.target.name;
		const value = event.target.value;
		setInputs((values) => ({ ...values, [name]: value }));
	};
	const handleSubmituser = (event: any) => {
		event.preventDefault();
		//console.log(inputs);
		//SubmitCreateUser(setLoading, setShowPopup, setModels, setShowEditPopup, inputs);
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();
		//console.log(inputs);
	};

	const handleReset = () => {
		setInputs({});
	};
	useEffect(() => {
		const unsubscribe = subscribe(setSidebarOpen);
		return () => unsubscribe();
	}, []);
	const onChange: DatePickerProps["onChange"] = (date: any, dateString: any) => {
		//	console.log(date, dateString);
	};
	const handlePageChange = (newPage: number, newPageSize: number) => {
		//	console.log("Page changed to:", newPage);
	};

	const handlePageSizeChange = (newPageSize: number) => {
		//console.log("Page size changed to:", newPageSize);
	};

	const filteredRows = models.filter((row: any) => {
		const emailId = row.EmailId || "";
		const contactnumber = row.ContactNumber || "";
		const userName = row.UserName || "";

		return (
			emailId.toLowerCase().includes(searchText.toLowerCase()) ||
			contactnumber.toLowerCase().includes(searchText.toLowerCase()) ||
			userName.toLowerCase().includes(searchText.toLowerCase())
		);
	});

	return (
		<Container maxWidth="xl">
			<div
				className={`smooth-transition`}
				style={{ marginLeft: sidebarOpen ? "-9px" : "", marginRight: "6px", width: "1100px" }}
			>
				<div
					// className={`smooth-transition`}
					style={{ marginLeft: sidebarOpen ? "145px" : "" }}
				>
					<div>
						<div className={sidebarOpen ? styles.UserHeaderPositionsideon : styles.UserHeaderPositionsideoff}>
							<h1>Team Members </h1>
						</div>
						<div
						// className={styles.UserCreateButtonPosition}
						>
							<button
								onClick={openPopup}
								className={styles.MemberButton}
							>
								Add Member
							</button>
						</div>
					</div>

					{loading ? (
						<div
							className={styles.Loaderstyles}
							// style={{
							// 	position: "absolute",
							// 	top: "50%",
							// 	left: "50%",
							// 	transform: "translate(-50%, -50%)",
							// 	zIndex: 1,
							// }}
						>
							<img
								src={loader}
								alt="Spinner"
								className={styles.LoaderSpinnerStyles}
								// style={{
								// 	width: "45px",
								// 	height: "45px",
								// }}
							/>
							<br />
						</div>
					) : (
						<div>
							{/* <CustomGrid
								rows={models}
								columns={columns}
								pageSize={100}
								totalRows={models.length}
								onPageChange={onPageChange}
								onPageSizeChange={onPageSizeChange}
								rowsPerPageOptions={[10, 20, 50, 100]}
								Toolbar={CustomToolbar}
							/> */}
							<Box
								className="userlistGrid"
								sx={{
									height: 467,
									width: sidebarOpen ? "100%" : "100%",
									marginTop: 2,
									marginLeft: sidebarOpen ? "1px" : "1px",
									top: "-28px",
									position: "relative",
								}}
							>
								<DataGrid
									sx={{
										borderColor: "black",
										"& .MuiDataGrid-columnHeaders": {
											backgroundImage: "linear-gradient(to right, rgb(30, 138, 201), rgba(62, 200, 241, 0.7))",
										},
										"& .MuiDataGrid-columnHeaderTitle": {
											fontWeight: "bold",
											color: "white",
										},
										"& .MuiDataGrid-columnHeader": {
											backgroundImage: "linear-gradient(to right, rgb(30, 138, 201), rgba(62, 200, 241, 0.7))",
											color: "white",
										},
										"& .MuiDataGrid-cell": {
											borderRight: "1px solid #ddd",
										},
										"& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
											borderRight: "1px solid #ddd",
										},
										"& .MuiDataGrid-row": {
											"& .MuiDataGrid-cell": {
												borderBottom: "none",
											},
										},
									}}
									rows={models}
									columns={columns}
									pagination
									paginationMode="server"
									slots={{
										columnMenu: (menuProps) => {
											if (
												menuProps.colDef.field === "EmailId" ||
												menuProps.colDef.field === "ContactNumber" ||
												menuProps.colDef.field === "AdminDeactivated"
											) {
												return (
													<UserCustomColumnMenu
														column={menuProps.colDef.field}
														setModels={setModels}
														setLoading={setLoading}
														skip={skip}
														top={top}
														OrganizationId={OrganizationId}
														setTotalcount={setTotalcount}
														orderByFields={orderByFields}
														filters={filters}
														setFilters={setFilters}
														{...menuProps}
														options={models.map((model: any) => model[menuProps.colDef.field] || "")}
														onSearch={handleSearch}
													/>
												);
											}
											return null;
										},
									}}
									getRowId={(row) =>
										row.AccountId ||
										row.Account_ID ||
										row.AccountName ||
										row.id ||
										row.Created_Date ||
										row.UserId ||
										row.Id ||
										row.OrganizationId ||
										row.ImageId
									}
									paginationModel={paginationModel}
									onPaginationModelChange={(model) => {
										setPaginationModel(model);
									}}
									rowCount={totalcount}
									pageSizeOptions={[15, 25, 50, 100]}
									localeText={{
										MuiTablePagination: {
											labelRowsPerPage: "Records Per Page",
										},
									}}
									disableRowSelectionOnClick={true}
									loading={loading}
									sortModel={sortModel}
									onSortModelChange={handleSortModelChange}
								/>
							</Box>
						</div>
					)}

					{showPopup ? (
						<UserCreate
							setModels={setModels}
							setLoading={setLoading}
							setShowPopup={setShowPopup}
							setShowEditPopup={setShowEditPopup}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}

					{showeditPopup ? (
						<EditUser
							showEditPopup={showeditPopup}
							setShowEditPopup={setShowEditPopup}
							userId={useridedit}
							fetchUserDataFromApi={fetchUserDataFromApi}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}
					{showResetPasswordPopup ? (
						<UserPasswordReset
							showResetPasswordPopup={showResetPasswordPopup}
							setShowResetPasswordPopup={setShowResetPasswordPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							filters={filters}
						/>
					) : (
						""
					)}

					{showDeletePopup ? (
						<div>
							<div></div>
							<div className="userDelete-popup">
								<div>
									<svg
										onClick={() => setShowDeletePopup(false)}
										className="close-icon"
										xmlns="http://www.w3.org/2000/svg"
										x="0px"
										y="0px"
										width="24"
										height="24"
										viewBox="0 0 50 50"
									>
										<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
									</svg>
								</div>
								<div className={styles.Deletepopupwidth}>
									<p>Are you sure you want to delete User</p>
									<button
										className={styles.DeletepopupCancelbutton}
										// style={{
										// 	position: "relative",
										// 	background: "#fff",
										// 	color: "#007bff",
										// 	border: "1px solid #007bff",
										// 	marginRight: "27px",
										// 	right: "0px",
										// 	top: "10px",
										// }}
										onClick={() => setShowDeletePopup(false)}
										// className="userButton"
									>
										Cancel
									</button>

									<button
										style={{ position: "relative", right: "0px", top: "10px" }}
										className="userButton"
										onClick={handleDeleteuser}
									>
										Delete
									</button>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</Container>
	);
};

export default UserList;
