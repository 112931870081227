import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField, IconButton, InputAdornment, Typography, Box
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

interface ChangePasswordPopupProps {
  open: boolean;
  onClose: () => void;
}

const PasswordFieldLabel: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Typography
    sx={{
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '500',
      color: 'rgba(34, 34, 34, 1)',
      marginBottom: '-14px',
      lineHeight: '20px'
    }}
  >
    {children}
  </Typography>
);

const ChangePasswordPopup: React.FC<ChangePasswordPopupProps> = ({ open, onClose }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '463px',
          height: 'auto',
          borderRadius: '8px',
          position: 'relative',
          padding: '20px',
        },
      }}
    >
      <DialogTitle sx={{ padding: '0px', marginBottom: '20px' }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: '500',
            lineHeight: '30px',
            color: 'rgba(34, 34, 34, 1)',
          }}
        >
          Change Password
        </Typography>
        <IconButton
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            width: '16px',
            height: '16px',
            color: 'rgba(175, 175, 175, 1)',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '0px', marginBottom: '20px' }}>
        <PasswordFieldLabel>Enter current password</PasswordFieldLabel>
        <TextField
          placeholder="Minimum 8 characters"
          type={showCurrentPassword ? 'text' : 'password'}
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              height: '47px', // Set the height
              borderRadius: '6px', // Set the border radius
              '&::placeholder': {
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '23px',
                textAlign: 'center',
              },
            },
          }}
          sx={{ marginBottom: '15px' }}
        />
        
        <PasswordFieldLabel>Enter new password</PasswordFieldLabel>
        <TextField
          placeholder="Minimum 8 characters"
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={handleNewPasswordChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              height: '47px', // Set the height
              borderRadius: '6px', // Set the border radius
              '&::placeholder': {
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '23px',
                textAlign: 'center',
              },
            },
          }}
          sx={{ marginBottom: '15px' }}
        />
        
        <PasswordFieldLabel>Re enter new password</PasswordFieldLabel>
        <TextField
          placeholder="Re enter password"
          type={showConfirmNewPassword ? 'text' : 'password'}
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                >
                  {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              height: '47px', // Set the height
              borderRadius: '6px', // Set the border radius
              '&::placeholder': {
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '23px',
                textAlign: 'center',
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ padding: '0px' }}>
        <Box sx={{ flexGrow: 1 }}></Box> {/* To push the button to the right */}
        <Button
          onClick={() => {/* Add save logic here */}}
          sx={{
            backgroundColor: '#B8D0D1',
            color: '#ffffff',
            textTransform: 'none',
            borderRadius: '8px',
            width: '100px',
            height: '36px',
            '&:hover': {
              backgroundColor: '#A5C3C5',
            }
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordPopup;
