import React, { useState , useEffect} from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Grid,
    Select,
    MenuItem,
    TextField,
    Button,
    Typography,
    Badge,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ClearIcon from '@mui/icons-material/Clear';
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";

interface Filter {
    id: number;
    column: string;
    operator: string;
    value: string;
}

interface FilterPopupProps {
    columns: string[];
    onApplyFilters: (filters: Filter[]) => void;
}

const FilterPopup: React.FC<FilterPopupProps> = ({ columns, onApplyFilters }) => {
    const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
    const [open, setOpen] = useState(false);
    const [filters, setFilters] = useState<Filter[]>([
        { id: 1, column: columns[0] || 'City', operator: 'contains', value: '' }
    ]);
    
    const activeFilterCount = filters.filter(filter => filter.value.trim() !== '').length;
    useEffect(() => {
        const unsubscribe = subscribe(setSidebarOpen);
        return () => unsubscribe();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleColumnChange = (id: number, event: any) => {
        const newFilters = filters.map(filter => 
            filter.id === id ? { ...filter, column: event.target.value, value: '' } : filter
        );
        setFilters(newFilters);
    };

    const handleOperatorChange = (id: number, event: any) => {
        const newFilters = filters.map(filter => 
            filter.id === id 
            ? { ...filter, operator: event.target.value, value: event.target.value === 'is any' ? '' : filter.value } 
            : filter
        );
        setFilters(newFilters);
    };

    const handleValueChange = (id: number, event: any) => {
        const newFilters = filters.map(filter => 
            filter.id === id ? { ...filter, value: event.target.value } : filter
        );
        setFilters(newFilters);
    };

    const handleAddFilter = () => {
        setFilters([...filters, { id: filters.length + 1, column: columns[0] || 'City', operator: 'contains', value: '' }]);
    };

    const handleDeleteFilter = (id: number) => {
        if (filters.length === 1) {
            setFilters([{ id: 1, column: columns[0] || 'City', operator: 'contains', value: '' }]);
        } else {
            setFilters(filters.filter(filter => filter.id !== id));
        }
    };

    const handleClearAll = () => {
        setFilters([{ id: 1, column: columns[0] || 'City', operator: 'contains', value: '' }]);
        onApplyFilters([]);
        setOpen(false);
    };

    const handleRemoveAll = () => {
        setFilters([{ id: 1, column: columns[0] || 'City', operator: 'contains', value: '' }]);
    };

    const handleApplyFilters = () => {
        onApplyFilters(filters);
        setOpen(false);
    };

    const isApplyDisabled = filters.some(filter =>
        (filter.value.trim() === '' && !['is empty', 'is not empty', 'is any'].includes(filter.operator))
    );
    

    return (
        <div>
            <IconButton sx={{ top: "20px" }} onClick={handleClickOpen}>
                <Badge badgeContent={activeFilterCount} color="primary">
                    <FilterListIcon sx={{"&:focusVisible": { outline: "none" }}} />
                </Badge>
                <Typography sx={{ fontSize: "15px" }}>Filters</Typography>
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                slotProps={{
                    backdrop:{invisible:true}
                }}
                sx={{
                    "& .MuiDialog-container": {
                        width: "600px",
                        marginTop: "6px",
                        marginLeft: sidebarOpen?"745px":"690px",
                    }
                }}
            >
                <DialogTitle>
                    Filter
                    <Grid container alignItems="center" justifyContent="flex-end">
                        <Button 
                            startIcon={<ClearIcon />} 
                            variant="text" 
                            color="secondary" 
                            onClick={handleClearAll}
                            sx={{ marginRight: 2 }}
                            disabled={filters.length === 0 || filters.every(filter => filter.value.trim() === '')}
                        >
                            CLEAR ALL
                        </Button>
                        <Button startIcon={<DeleteIcon />} variant="text" color="primary" onClick={handleRemoveAll}>
                            REMOVE ALL
                        </Button>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent sx={{ minWidth: '300px' }}>
                    {filters.map(filter => (
                        <Grid container spacing={2} alignItems="center" key={filter.id} sx={{ marginBottom: '10px' }}>
                            <Grid item xs={1}>
                                <IconButton aria-label="delete" size="small" onClick={() => handleDeleteFilter(filter.id)}>
                                    <DeleteIcon sx={{ color: 'red' }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={3}>
                                <label>Column</label>
                                <Select
                                    value={filter.column}
                                    onChange={(event) => handleColumnChange(filter.id, event)}
                                    fullWidth
                                    variant='standard'
                                >
                                    {columns.map(col => (
                                        <MenuItem key={col} value={col}>{col}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                <label>Operator</label>
                                <Select
                                    value={filter.operator}
                                    onChange={(event) => handleOperatorChange(filter.id, event)}
                                    fullWidth
                                    variant='standard'
                                >
                                    <MenuItem value="contains">contains</MenuItem>
                                    <MenuItem value="equals">equals</MenuItem>
                                    <MenuItem value="starts with">starts with</MenuItem>
                                    <MenuItem value="ends with">ends with</MenuItem>
                                    <MenuItem value="is empty">is empty</MenuItem>
                                    <MenuItem value="is not empty">is not empty</MenuItem>
                                    <MenuItem value="is any">is any</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={5} sx={{ minWidth: '200px' }}>
                                {filter.operator !== 'is empty' && filter.operator !== 'is not empty' && (
                                    <>
                                        <label>Value</label>
                                        <TextField
                                            value={filter.value}
                                            onChange={(event) => handleValueChange(filter.id, event)}
                                            placeholder="Filter value"
                                            fullWidth
                                            variant='standard'
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container spacing={2} justifyContent="space-between" sx={{ marginTop: '16px' }}>
                        <Grid item>
                            <Button startIcon={<AddBoxIcon />} variant="text" color="primary" onClick={handleAddFilter}>
                                ADD FILTER
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={isApplyDisabled} variant="contained" color="primary" onClick={handleApplyFilters}>
                                APPLY
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default FilterPopup;
