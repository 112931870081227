import React,{useState,useEffect} from 'react';
import { useAuth } from '../auth/AuthProvider';
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { useLocation } from 'react-router-dom';
import userManager from '../auth/UseAuth';
//import signOut from '../../Api';
import axios from 'axios';
import { GetUserDetails } from '../../services/UserService';

function Home() {
	const { user } = useAuth();
	let loginUserName : string  = "test";
	// const userinfo = GetUserDetails();
    //     userinfo.then(userinfo => {
	// 		localStorage.setItem("organizationId", userinfo?.OrganizationId ?? ""); 
	// 		loginUserName = userinfo?.FirstName ?? "";
    //       console.log(userinfo?.FirstName);  
    //     });
	//const  userlogout  = signOut;
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  
	useEffect(() => {
		const unsubscribe = subscribe(setSidebarOpen);
		return () => unsubscribe();
		
	}, []);

	useEffect(() => {
		localStorage.setItem("name", loginUserName);

		console.log(user?.access_token)
		
	}, [user]);
	
	useEffect(() => {
		const baseUrl = 'http://localhost:60552/api';
		const apiService = axios.create({
			baseURL: baseUrl,
			headers: {
				"Content-Type": "application/json",
			},
		});
		
		
	})

	return (
		<div className={`smooth-transition`} style={{ marginLeft: sidebarOpen ? "250px" : "" }}>
		<h1 style={{ textAlign: 'center' }}>Home Page</h1>
			{user?.access_token ? (
				<div>
				<p style={{ textAlign: 'center' }}>Welcome, {loginUserName}</p>
				</div>
			) : null}
		</div>
	);
}

export default Home;
