import React, { useState, useEffect, ChangeEvent, FocusEvent, FormEvent } from "react";
import {
	TextField,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	FormHelperText,
	SelectChangeEvent,
	Snackbar,
	Alert,
	Box,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { SubmitUserDetails } from "../../services/UserService";
import { adminUrl } from "../../services/APIService";
import { useSnackbar } from "../../SnackbarContext";
import styles from "./UserStyles.module.scss";
//import MuiPhoneNumber from "mui-phone-number";

type InputFields = {
	FirstName: string;
	LastName: string;
	EmailId: string;
	Password: string;
	ContactNumber: string;
	Gender: string;
	DateofBirth: string;
	OrganizationId: string;
};

type ErrorFields = Partial<InputFields>;

const EditUser = (props: any) => {
	const {
		showEditPopup,
		setShowEditPopup,
		userId,
		fetchUserDataFromApi,
		setModels,
		setLoading,
		fetchUsersList,
		skip,
		top,
		OrganizationId,
		setTotalcount,
		orderByFields,
		filters,
	} = props;
	const { openSnackbar } = useSnackbar(); // Use the global snackbar context

	const [userDetails, setUserDetails] = useState({
		FirstName: "",
		LastName: "",
		EmailId: "",
		Password: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: OrganizationId,
	});
	const [inputs, setInputs] = useState<InputFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		Password: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: OrganizationId,
	});

	const [errors, setErrors] = useState<ErrorFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		Password: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: "",
	});
	const [isValid, setIsValid] = useState(false);

	const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setInputs((prev) => ({ ...prev, [name]: value }));
		let processedValue = value;
		let error = "";
		const numericValue = value.replace(/[^0-9]/g, "");
		if (name === "ContactNumber") {
			// Format contact number as '+XX-XXXXXXXXXX'
			const numericValue = value.replace(/[^0-9+]/g, "");
			if (numericValue.length !== 13 || !numericValue.startsWith("+") || numericValue[3] !== "-") {
				error = "Contact Number must be in the format +XX-XXXXXXXXXX.";
			}
			processedValue = numericValue;
			setInputs((prev) => ({ ...prev, [name]: processedValue }));
		}
		if (name === "FirstName" || name === "LastName") {
			// Remove special characters, allowing only letters, numbers, and spaces
			processedValue = value.replace(/[^a-zA-Z]/g, "");
		}
		setInputs((prev) => ({ ...prev, [name]: processedValue }));

		if (name === "FirstName" && processedValue.length < 5) {
			error = "First Name must be at least 5 characters.";
		}

		// Validation for LastName
		if (name === "LastName" && processedValue.length < 3) {
			error = "Last Name must be at least 3 characters.";
		}

		if (name === "Password" && value.length > 10) {
			error = "Password cannot exceed 10 characters.";
		}
		// if (name !== "Gender" && name !== "DateofBirth" && name !== "ContactNumber" && value.length < 5) {
		// 	error = `${name} must be at least 5 characters.`;
		// }
		if (name === "EmailId") {
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (value && !emailRegex.test(value)) {
				error = "Invalid email format.";
			}
			setInputs((prevInputs) => ({
				...prevInputs,
				[name]: value,
			}));
		}
		setIsValid(true);
		setErrors((prev) => ({ ...prev, [name]: error }));
	};
	const [age, setAge] = React.useState("");

	// const handleGenderChange = (event: ChangeEvent<HTMLSelectElement>) => {
	// 	const { name, value } = event.target;
	// 	setInputs((prev) => ({ ...prev, [name]: value }));
	// };

	const handleGenderChange = (event: SelectChangeEvent) => {
		setAge(event.target.value);
		const value = event.target.value;
		setIsValid(true);
		setInputs((prev: any) => ({
			...prev,
			Gender: value,
		}));
	};
	useEffect(() => {
		if (showEditPopup) {
			fetchUserDetails(userId);
		}
	}, [showEditPopup]);

	const fetchUserDetails = async (id: any) => {
		try {
			const response = await fetch(`${adminUrl}/User/GetUserByUserId?userId=${id}`);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const data = await response.json();
			setUserDetails({
				FirstName: data.FirstName,
				LastName: data.LastName,
				EmailId: data.EmailId,
				Password: "**********", // Do not populate the password field for security reasons
				ContactNumber: data.ContactNumber,
				Gender: data.Gender,
				DateofBirth: data.DateofBirth,
				OrganizationId: data.OrganizationId,
			});
			setInputs({
				FirstName: data.FirstName,
				LastName: data.LastName,
				EmailId: data.EmailId,
				Password: "**********", // Do not populate the password field for security reasons
				ContactNumber: data.ContactNumber,
				Gender: data.Gender,
				DateofBirth: data.DateofBirth,
				OrganizationId: data.OrganizationId,
			});
		} catch (error) {
			console.error("Failed to fetch user details:", error);
		}
	};

	// const handleChange = (e: any) => {
	// 	const { name, value } = e.target;
	// 	setUserDetails((prevDetails) => ({
	// 		...prevDetails,
	// 		[name]: value,
	// 	}));
	// };

	const handleChange = (event: SelectChangeEvent) => {
		setAge(event.target.value);
		//console.log(age);
	};
	const [selectedDate, setSelectedDate] = useState();
	const handleDateChange = (date: any) => {
		setSelectedDate(date);
		setIsValid(true);
		setInputs((prev) => ({ ...prev, DateofBirth: date ? date.format("MM/DD/YYYY") : "" }));
		if (!date) {
			setErrors((prev) => ({ ...prev, DateofBirth: "Date of Birth is required." }));
			setIsValid(false);
		} else {
			setErrors((prev) => ({ ...prev, DateofBirth: "" }));
		}
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		const newErrors: ErrorFields = {};

		(Object.keys(inputs) as (keyof InputFields)[]).forEach((key) => {
			if (!inputs[key]) {
				newErrors[key] = `${key} is required.`;
				setIsValid(false);
			} else if (key === "ContactNumber") {
				const contactNumberRegex = /^\+\d{2}-\d{10}$/;
				if (!contactNumberRegex.test(inputs[key])) {
					newErrors[key] = "Contact Number must be in the format +XX-XXXXXXXXXX.";
					setIsValid(false);
				}
			} else if (key === "FirstName" && inputs[key].length < 5) {
				newErrors[key] = "First Name must be at least 5 characters.";
				setIsValid(false);
			} else if (key === "LastName" && inputs[key].length < 3) {
				newErrors[key] = "Last Name must be at least 3 characters.";
				setIsValid(false);
			}
		});
		setErrors(newErrors);
		console.log(newErrors + "" + isValid);
		if (isValid) {
			setLoading(true);
			try {
				await SubmitUserDetails(
					setLoading,
					setModels,
					setShowEditPopup,
					inputs,
					skip,
					top,
					OrganizationId,
					setTotalcount,
					openSnackbar,
					orderByFields,
					filters
				);
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		showEditPopup && (
			<div className="userEdit-popup">
				<div>
					<h1>Edit Member</h1>
					<svg
						onClick={() => setShowEditPopup(false)}
						className="close-icon"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						width="24"
						height="24"
						viewBox="0 0 50 50"
					>
						<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
					</svg>
				</div>
				<form onSubmit={handleSubmit}>
					<TextField
						name="FirstName"
						value={inputs.FirstName}
						onChange={handleTextFieldChange}
						// onBlur={handleBlur}
						label="First Name*"
						helperText={errors.FirstName}
						variant="standard"
						error={!!errors.FirstName}
						inputProps={{ maxLength: 50 }}
						sx={{
							"& .MuiFormHelperText-root": {
								color: errors.FirstName ? "red" : "inherit",
							},
							width: "250px",
							marginBottom: "10px",
						}}
					/>
					<TextField
						name="LastName"
						value={inputs.LastName}
						onChange={handleTextFieldChange}
						// onBlur={handleBlur}
						label="Last Name*"
						helperText={errors.LastName}
						variant="standard"
						error={!!errors.LastName}
						inputProps={{ maxLength: 50 }}
						sx={{
							"& .MuiFormHelperText-root": {
								color: errors.LastName ? "red" : "inherit",
							},
							width: "250px",
							marginBottom: "10px",
						}}
					/>
					{/* <TextField
						name="UserName"
						required
						value={inputs.FirstName + " " + inputs.LastName}
						onChange={handleTextFieldChange}
						// onBlur={handleBlur}
						label="User Name"
						helperText={errors.UserName}
						variant="standard"
						error={!!errors.UserName}
						sx={{
							"& .MuiFormHelperText-root": {
								color: errors.UserName ? "red" : "inherit",
							},
							width: "250px",
							marginBottom: "16px",
						}}
					/> */}
					<TextField
						//required
						name="EmailId"
						value={inputs.EmailId}
						//onChange={handleTextFieldChange}
						label="Email"
						helperText={errors.EmailId}
						placeholder="example@gmail.com"
						variant="standard"
						error={!!errors.EmailId}
						inputProps={{ readOnly: true }}
						sx={{
							"& .MuiFormHelperText-root": {
								color: errors.EmailId ? "red" : "inherit",
							},
							width: "250px",
							marginBottom: "10px",
						}}
					/>
					<TextField
						name="ContactNumber"
						value={inputs.ContactNumber}
						onChange={(event) => {
							// Allow only digits after the '+' and '-' symbols
							let value = event.target.value;
							// Remove everything except digits and '+' symbol
							let cleanedValue = value.replace(/[^0-9+]/g, "");
							// Ensure it starts with '+' and is followed by the country code (two digits)
							if (cleanedValue.startsWith("+")) {
								cleanedValue = "+" + cleanedValue.slice(1, 3) + "-" + cleanedValue.slice(3);
							}
							setIsValid(true);
							setInputs((prev) => ({ ...prev, ContactNumber: cleanedValue }));
						}}
						label="Contact Number"
						helperText={errors.ContactNumber}
						placeholder="+XX-XXXXXXXXXX"
						variant="standard"
						error={!!errors.ContactNumber}
						//required
						inputProps={{
							inputMode: "numeric",
							pattern: "\\+\\d{2}-\\d{10}",
							maxLength: 14, // '+XX-XXXXXXXXXX' has 14 characters
						}}
						sx={{
							"& .MuiFormHelperText-root": {
								color: errors.ContactNumber ? "red" : "inherit",
							},
							width: "250px",
							marginBottom: "8px",
						}}
					/>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Date of Birth"
							value={inputs.DateofBirth}
							onChange={handleDateChange}
							renderInput={(params) => (
								<TextField
									{...params}
									error={Boolean(errors.DateofBirth)}
									helperText={errors.DateofBirth}
									variant="standard"
								/>
							)}
						/>
					</LocalizationProvider>
					<FormControl
						variant="standard"
						sx={{ m: 1, minWidth: 120 }}
					>
						<InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
						<Select
							name="Gender"
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							value={inputs.Gender}
							onChange={handleGenderChange}
							label="Gender"
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							<MenuItem value={"Male"}>Male</MenuItem>
							<MenuItem value={"Female"}>Female</MenuItem>
							<MenuItem value={"Other"}>Other</MenuItem>
						</Select>
					</FormControl>
					{/* <TextField
						required
						name="ContactNumber"
						value={inputs.ContactNumber}
						onChange={(event) => {
							// Allow only digits in the input
							const numericValue = event.target.value.replace(/\D/g, "");
							setInputs((prev) => ({ ...prev, ContactNumber: numericValue }));
						}}
						// onChange={handleTextFieldChange}
						label="Contact Number"
						helperText={errors.ContactNumber}
						placeholder="9999-99-9999"
						variant="standard"
						error={!!errors.ContactNumber}
						//type="number"
						inputProps={{
							inputMode: "numeric",
							pattern: "[0-9]*",
							maxLength: 10, // Optional: To enforce max length at input level
						}}
						sx={{
							"& .MuiFormHelperText-root": {
								color: errors.ContactNumber ? "red" : "inherit",
							},
							width: "250px",
							marginBottom: "16px",
						}}
					/> */}
					{/* <MuiPhoneNumber
						name="ContactNumber"
						label="Contact Number"
						data-cy="user-phone"
						defaultCountry={"in"} // Set default country to India
						value={inputs.ContactNumber} // Adjust according to your state structure
						onChange={handleTextFieldChange} // Update phone state
						variant="standard" // Style to match other form elements
						error={!!errors.ContactNumber} // Display error state if present
						helperText={errors.ContactNumber} // Error message
						required
						sx={{
							width: "250px",
							marginBottom: "14px",
							"& .MuiFormHelperText-root": {
								color: errors.ContactNumber ? "red" : "inherit",
							},
						}}
					/> 
					 <FormControl
						variant="standard"
						sx={{
							width: "250px",
							marginBottom: "16px",
						}}
						error={!!errors.Gender}
					>
					 <InputLabel>Gender:</InputLabel> 
						<select
							name="Gender"
							value={inputs.Gender}
							onChange={handleGenderChange}
							required
						>
							<option value={""}>Gender</option>
							<option value={"male"}>Male</option>
							<option value={"female"}>Female</option>
						</select> 
					<FormHelperText>{errors.Gender}</FormHelperText> 
					</FormControl> */}

					{/* <FormControl
						variant="standard"
						sx={{ m: 1, minWidth: 120 }}
					>
						<InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
						<Select
							name="Gender"
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							value={age}
							onChange={handleGenderChange}
							label="Gender"
						>
							<MenuItem value="">select</MenuItem>
							<MenuItem value={"Male"}>Male</MenuItem>
							<MenuItem value={"Female"}>Female</MenuItem>
							<MenuItem value={"Other"}>Other</MenuItem>
						</Select>
					</FormControl> */}
					{/* <input
						type="text"
						name="FirstName"
						value={userDetails.FirstName}
						onChange={handleChange}
						placeholder="First Name*"
					/>
					<input
						type="text"
						name="LastName"
						value={userDetails.LastName}
						onChange={handleChange}
						placeholder="Last Name"
					/>
					<input
						type="text"
						name="UserName"
						value={userDetails.UserName}
						onChange={handleChange}
						placeholder="User Name*"
					/>
					<input
						type="text"
						name="EmailId"
						value={userDetails.EmailId}
						onChange={handleChange}
						placeholder="Email Id *"
					/>
					 <input
						type="password"
						name="Password"
						value={userDetails.Password}
						onChange={handleChange}
						placeholder="Password *"
					/> 
					<input
						name="ContactNumber"
						type="text"
						value={userDetails.ContactNumber}
						onChange={handleChange}
						placeholder="Contact Number"
					/>
					<select
						name="Gender"
						value={userDetails.Gender}
						onChange={handleChange}
					>
						<option value="male">Male</option>
						<option value="female">Female</option>
					</select>
					 <input
						name="DateofBirth"
						type="date"
						value={userDetails.DateofBirth}
						onChange={handleChange}
						placeholder="Date of Birth"
					/> */}
					<div style={{ marginLeft: "40px", display: "flex", gap: "20px" }}>
						<button
							className={styles.usereditcancelButton}
							// style={{
							// 	position: "relative",
							// 	background: "#fff",
							// 	color: "#007bff",
							// 	border: "1px solid #007bff",
							// 	marginRight: "27px",
							// 	right: "0px",
							// }}
							onClick={() => setShowEditPopup(false)}
							//className="userButton"
						>
							Cancel
						</button>
						<button
							className={styles.usereditsaveButton}
							//style={{ position: "relative", right: "0px" }}
							//className="userButton"
							type="submit"
						>
							Save
						</button>
					</div>
				</form>
			</div>
		)
	);
};

export default EditUser;
