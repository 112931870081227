  
  
import React from 'react';


const DomainSettings = () => {
    return (
      <h1>Domain Settings Page</h1>
    );
  };
  

export default DomainSettings