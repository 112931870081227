import React, { Suspense } from "react"
import "./App.scss"
import Routing from "./routing/Routings"
import Layout from "./components/layout/Layout"
import { RtlProvider } from "./RtlContext";
import { SnackbarProvider } from "./SnackbarContext";
import { UnAuthRouteComponent,SuperAdminRouteComponent } from "./routing/Routings";

function App() {
	const accesstoken = localStorage.getItem("oidc.user:https://localhost:44301/:react_dap")
	console.log(accesstoken);
	const userType = localStorage.getItem("userType");
	return (
		<SnackbarProvider>
			<Suspense fallback={<div>Loading...</div>}>
				<RtlProvider>
				{accesstoken && userType?.toLowerCase() === "superadmin" ? (
					<Layout>
						<SuperAdminRouteComponent />
					</Layout>
				) : accesstoken && userType?.toLowerCase() === "admin" ? (
					<Layout>
							<Routing />
					</Layout>
				) : (
					<Layout>
							<UnAuthRouteComponent />
					</Layout>
				)};									
				</RtlProvider>
			</Suspense>
		</SnackbarProvider>
	);
}

export default App
